import React from 'react';
import { Link } from 'react-router-dom';

const CreateAccountButton: React.FC = () => {
  return (
    <Link to="/register" className="btn btn-primary btn-block">
      Create an Account
    </Link>
  );
};

export default CreateAccountButton;
