import { gql } from '@apollo/client';

export const GET_OFFICE_TYPES_GQL = gql`
  query {
    __type(name: "OfficeType") {
      enumValues {
        name
      }
    }
  }
`;

export const GET_OFFICE_CATEGORIES_GQL = gql`
  query _categories {
    _categories(first: 1000) {
      data {
        id
        name
      }
    }
  }
`;
