/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Col, Form, Row, UncontrolledTooltip } from 'reactstrap';
import { useForm, FormProvider } from 'react-hook-form';
import SimpleInput from '../SimpleInput';

// will be enabled again in the near future
// import { Link } from 'react-router-dom';

import { emailRegex } from '../../utils/constants';
import { useMutation } from '@apollo/client';
import { currentAuthQuery, loginMutation } from '../../utils/gql';
import useToast from '../../hooks/useToast';
import CustomButton from '../CustomButton';

interface LoginFormVariables {
  email: string;
  password: string;
}

interface LoginFormProps {
  onSuccess(props?: any): void;
}
const LoginForm: React.FC<LoginFormProps> = ({ onSuccess }) => {
  const methods = useForm<LoginFormVariables>();
  const { toast } = useToast();

  const [makeLogin, { loading }] = useMutation(loginMutation, {
    update: async (cache, { data: { login } }) => {
      cache.writeQuery({
        query: currentAuthQuery,
        data: { authorization: login },
        broadcast: true,
      });

      onSuccess(login);
    },
    onError: (errros) => toast('error', errros?.message),
  });

  const onSubmit = (variables: LoginFormVariables) =>
    makeLogin({
      variables,
    });
  return (
    <>
      <Row>
        <Col>
          <h3>Login</h3>
        </Col>
      </Row>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <SimpleInput
                field="email"
                label="Email"
                extraRegisterProps={{ pattern: emailRegex }}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SimpleInput
                label="Password"
                field="password"
                type="password"
                required
              />
            </Col>
          </Row>
          <Row>
            <Col className="pl-4">
              {/* will be enabled again in the near future 
               <Link to="#forgotPassword">Forgot your password?</Link> */}

              <p id="tooltipBuyingPublic" className="cursor-pointer muted ml-2">
                Forgot your password?
              </p>
              <UncontrolledTooltip
                placement="bottom"
                target="tooltipBuyingPublic"
              >
                An account email is required.
              </UncontrolledTooltip>
            </Col>
            <Col className="d-flex justify-content-end mr-3">
              <CustomButton text="Login" isLoading={loading} />
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </>
  );
};

export default LoginForm;
