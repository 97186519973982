export const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const CRM_URL =
  process.env.RAZZLE_APP_CRM_URL || 'http://localhost:3000';
export const FIRM_TYPES_MOBILE = {
  offices: [
    'appraisal_services',
    'broker',
    'carver',
    'designer_cad_cam',
    'designer_drawer',
    'diamond_cutter',
    'drill',
    'enameler',
    'engraver',
    'enhancer',
    'estate',
    'gemology_services',
    'jeweler',
    'jewelry_brand',
    'lapidary_gem_cutter',
    'manufacture',
    'metalsmith',
    'miner',
    'pearl_stringer',
    'retailer',
    'refiner',
    'mold_maker',
    'scrap_buyer',
  ],

  categories: [
    'Diamonds',
    'Color Diamonds',
    'Gemstone',
    'Pearls',
    'Polished',
    'Rough',
    'Melee',
    'Jewelry',
    'Watches',
    'Findings',
    'Metals',
  ],
};

export const FIRM_TYPES = {
  first: [
    { id: 1, name: 'Broker' },
    { id: 2, name: 'Appraisal Services' },
    { id: 3, name: 'Drill' },
    { id: 4, name: 'Estate' },
    { id: 5, name: 'Laboratory Services' },
    { id: 6, name: 'Jeweler' },
    { id: 7, name: 'Manufacture' },
    { id: 8, name: 'Enameler' },
    { id: 9, name: 'Lapidary - Gem Cutter' },
    { id: 10, name: 'Miner' },
    { id: 11, name: 'Engraver' },
    { id: 12, name: 'Organize SKU Display' },
    { id: 13, name: 'Public' },
    { id: 14, name: 'Enhancer' },
    { id: 15, name: 'Pearl Stringer' },
    { id: 16, name: 'Retailer' },
    { id: 17, name: 'Carver' },
    { id: 18, name: 'Refiner' },
    { id: 19, name: 'Jewelry Brand' },
    { id: 20, name: 'Diamond Cutter' },
    { id: 21, name: 'Scrap Buyer' },
    { id: 22, name: 'Designer Drawer' },
    { id: 23, name: 'Designer CAD/CAM' },
    { id: 24, name: 'Metalsmith' },

    { id: 25, name: 'Mold Maker' },
  ],
  second: [
    { id: 1, name: 'Diamonds' },
    { id: 2, name: 'Color' },
    { id: 3, name: 'Gemstones' },
    { id: 4, name: 'Pearls' },
    { id: 5, name: 'Polished' },
    { id: 6, name: 'Rough' },
    { id: 7, name: 'Melee' },
    { id: 8, name: 'Jewelry' },
    { id: 9, name: 'Watches' },
    { id: 10, name: 'Findings' },
    { id: 11, name: 'Metals' },
  ],
};

export const firmTypesToMap = [
  'Broker',
  'Appraisal Services',
  'Jeweler',
  'Estate',
  'Laboratory Services',
  'Lapidary - Gem Cutter',
  'Manufacture',
  'Enameler',
  'Carver',
  'Miner',
  'Engraver',
  'Drill',
  'Public',
  'Enhancer',
  'Pearl Stringer',
  'Retailer',
  'Diamond Cutter',
  'Refiner',
  'Jewelry Brand',
  'Design Drawer',
  'Scrap Buyer',
  'Wholesaler',
  'Designer CAD/CAM',
  'Metalsmith',
  'Mold Maker',
];

export const JOB_TITLES = [
  { id: 1, name: 'Owner' },
  { id: 27, name: 'CEO' },
  { id: 30, name: 'President' },
  { id: 33, name: 'Vice President' },
  { id: 0, name: 'Executive/Employee' },
];
