import React, { useState } from 'react';
import { Navbar, NavbarToggler, Collapse, Nav, NavItem } from 'reactstrap';
import './styles.scss';
import LoginModal from './LoginModal';
import { Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';

const MENU_ITEMS = [
  { to: '/', label: 'Home' },
  { to: '/retailers', label: ' Retailers / Suppliers' },
  { to: '/management', label: 'Management / Pricing' },
  { to: '/work-orders', label: '  Work Orders / Services' },
  { to: '/register', label: '   Register / Contact' },
];

const Menu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggle = () => setIsOpen(!isOpen);
  return (
    <>
      <Navbar light expand="lg" color="white">
        <Link to="/" className="navbar-brand">
          <img src="/images/wgt-logo-small.png" />
        </Link>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            {MENU_ITEMS.map((menu, key) => (
              <NavItem key={`menu-${key}`}>
                <Link
                  to={menu.to}
                  className={classnames('nav-link', {
                    active: location?.pathname === menu.to,
                  })}
                >
                  {menu.label}
                </Link>
              </NavItem>
            ))}
          </Nav>

          <LoginModal />
        </Collapse>
      </Navbar>
      <div className="navbar-bottom-images">
        <span className="world-gem-trade-text">WORLD GEM TRADE</span>
        <span className="trace-for-better-world-text">
          Trace for a better world
        </span>
      </div>
    </>
  );
};

export default Menu;
