import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Form } from 'reactstrap';
import userInvoicesContext from '../../hooks/userInvoicesContext';
import CustomInputGroup from '../CustomInputGroup';
import './styles.scss';

const IndexSearchService: React.FC = () => {
  const methods = useForm();
  const { setActive, active } = userInvoicesContext();

  // more logic will be applyed here when backend integration is ready
  const onSubmit = () =>
    setActive({
      type: 'invoice',
    });

  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="enter-email-field"
      >
        <CustomInputGroup
          buttonLabel="View"
          placeholder="Enter Invoice ID"
          field={'code'}
          active={!!active?.type}
          required
        />
      </Form>
    </FormProvider>
  );
};

export default IndexSearchService;
