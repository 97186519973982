import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Country } from '@wgt/types';
import { CREATE_NEWSLETTER } from '../services/gql/newsletter';
import useToast from './useToast';

export interface NewsletterVariables {
  email: string;
  name?: string;
  company_name?: string;
  phone?: string;
  address?: string;
  address_2?: string;
  country_id?: number;
  country?: Country;
  state?: string;
  city?: string;
  zipcode?: string;
  buying_public?: boolean;
  jewlry_industry?: boolean;
  office_types?: string[];
  category_ids?: number[];
}

interface NewsletterSubscriptionVariables {
  email: string;
}

export interface NewsletterCreateVariables
  extends NewsletterSubscriptionVariables {
  ip: string;
}

export interface NewsletterSubscriptionCreateVariables {
  variables: NewsletterCreateVariables;
}

export interface NewsletterUpdateVariables
  extends NewsletterSubscriptionVariables {
  newsletterSubscription?: NewsletterVariables;
}

export interface NewsletterSubscriptionUpdateVariables {
  variables: NewsletterUpdateVariables;
}

interface NewsletterContextData {
  isExtraOpen?: boolean;
  setExtraOpen(open: boolean): void;
  creating?: boolean;
  createNewsletter(variables: NewsletterSubscriptionCreateVariables): void;
  isLoading?: boolean;
}

export const NewsletterContext = React.createContext<NewsletterContextData>(
  {} as NewsletterContextData,
);

function scrollDown() {
  window.scrollTo(0, document.body.scrollHeight);
}

export const NewsletterProvider: React.FC = ({ children }) => {
  const [isExtraOpen, setExtraOpen] = useState(false);
  const { toast } = useToast();

  const [createNewsletter, { loading: creating }] = useMutation<
    NewsletterSubscriptionCreateVariables
  >(CREATE_NEWSLETTER, {
    onCompleted: () => {
      setExtraOpen(true);
      setTimeout(() => {
        scrollDown();
      });
    },
    onError: () => {
      toast('error', 'This email has been previously entered.');
    },
  });

  const contextValues: NewsletterContextData = {
    isExtraOpen,
    setExtraOpen,
    createNewsletter,
    creating,
    isLoading: creating,
  };
  return (
    <NewsletterContext.Provider value={contextValues}>
      {children}
    </NewsletterContext.Provider>
  );
};

export default function userNewsletterContext(): NewsletterContextData {
  return useContext(NewsletterContext);
}
