import { gql } from '@apollo/client';

export const FIRM_EXISTS = gql`
  query _firmExists($filter: SiteFirmFilterInput!) {
    _firmExists(first: 1000, filter: $filter) {
      data {
        id
        name
        email
        website
        address {
          number
          address
          state
          city
          country {
            name
          }
          phone
          postal_code
        }
        extra {
          logo_url
        }
        offices {
          id
          name
          address
          state
          city
          phone
          country {
            name
          }
        }
      }
    }
  }
`;

export const SIGN_UP = gql`
  mutation _firmSignUp(
    $firm: FirmSignUpFirmInput
    $user: FirmSignUpUserInput
    $office: FirmSignUpOfficeInput
  ) {
    _firmSignUp(firm: $firm, user: $user, office: $office) {
      message
    }
  }
`;

export const CREATE_EMPLOYEE_JOIN_REQUEST = gql`
  mutation createEmployeeJoinRequest(
    $firm_id: ID!
    $first_name: String!
    $last_name: String!
    $email: String!
    $phone: String!
    $business_phone: String
    $business_phone_extension: String
    $business_mobile: String
    $message: String
    $office_id: ID
  ) {
    createEmployeeJoinRequest(
      firm_id: $firm_id
      first_name: $first_name
      last_name: $last_name
      email: $email
      phone: $phone
      business_phone: $business_phone
      business_phone_extension: $business_phone_extension
      business_mobile: $business_mobile
      message: $message
      office_id: $office_id
    ) {
      message
    }
  }
`;

export const CHECK_USER_EXIST_BY_EMAIL = gql`
  query _userExists($filter: SiteUserExistsFilterInput!) {
    _userExists(filter: $filter) {
      exists
    }
  }
`;
