import React from 'react';
import { Form, FormGroup, Button, Row, Col, Input, Label } from 'reactstrap';

const RegisterFirmForm: React.FC = () => {
  return (
    <Form>
      <Row>
        <Col>
          <FormGroup inline>
            <Label>Company</Label>
            <Input />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup inline>
            <Label>First name</Label>
            <Input />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup inline>
            <Label>Last name</Label>
            <Input />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup inline>
            <Label>Address</Label>
            <Input />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup inline>
            <Label>State</Label>
            <Input />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup inline>
            <Label>Zip/Postal code</Label>
            <Input />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup inline>
            <Label>Country</Label>
            <Input />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup inline>
            <Label>City</Label>
            <Input />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup inline>
            <Label>Mobile</Label>
            <Input />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup inline>
            <Label>Email</Label>
            <Input />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup inline>
            <Label>Phone</Label>
            <Input />
          </FormGroup>
        </Col>
        <Col />
      </Row>
      <Row>
        <Col sm={12}>
          <p>Let’s make sure we set your account up correctly. </p>
        </Col>
        <Col sm={12}>
          <FormGroup>
            <Label>Please describe your business.</Label>
            <textarea className="form-control" />
          </FormGroup>
        </Col>
        <Col sm={12}>
          <Button>Send</Button>
        </Col>
      </Row>
    </Form>
  );
};

export default RegisterFirmForm;
