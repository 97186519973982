/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import {
  Modal,
  ModalBody,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { useForm, FormProvider } from 'react-hook-form';
import {
  SiteFirm,
  EmployeeJoinRequestInput,
  EmployeeJoinRequest,
} from '@wgt/types';
import { useMutation, ApolloError } from '@apollo/client';
import SimpleInput from '../../SimpleInput';
import SelectOfficeCard from '../../SelectOfficeCard';
import { CREATE_EMPLOYEE_JOIN_REQUEST } from '../../../services/gql/account';
import useRegisterContext from '../../../hooks/useRegister';
import useToast from '../../../hooks/useToast';

interface Props {
  toggleRequestModal: () => void;
  firm: SiteFirm;
}

export default function RequestToJoin({
  toggleRequestModal,
  firm,
}: Props): JSX.Element {
  const [formError, setFormError] = useState<string>('');

  const { toast } = useToast();

  const methods = useForm();
  const { handleSubmit, register, errors, getValues } = methods;

  const { setRequestSent, setShowListFirm, userData } = useRegisterContext();

  const [create] = useMutation<EmployeeJoinRequestInput>(
    CREATE_EMPLOYEE_JOIN_REQUEST,
    {
      onCompleted: () => {
        const data: EmployeeJoinRequest = getValues([
          'id',
          'first_name',
          'last_name',
          'email',
          'business_phone',
          'business_phone_extension',
          'business_mobile',
          'message',
          'phone',
        ]);

        setRequestSent(data);
        setShowListFirm(false);
      },
      onError: (error: ApolloError) => {
        const validations = error?.graphQLErrors?.[0]?.extensions?.validation;
        setFormError(Object.values(validations).toString());
        toast('error', 'Please correct the following errors and try again');
      },
    },
  );

  const onSubmit = (data: EmployeeJoinRequestInput) => {
    const variables = {
      ...data,
      office_id: data.office?.id,
      office: undefined,
      phone: data.business_phone,
      firm_id: firm.id,
    };

    create({ variables });
  };

  return (
    <Modal
      isOpen={true}
      toggle={toggleRequestModal}
      size="xl"
      className="modal-request-join"
    >
      <ModalBody>
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mb-3">
              <Col
                xs={{ size: 12, order: 2 }}
                md={{ size: 4, offset: 1, order: 1 }}
                className="d-flex justify-content-center justify-content-md-left"
              >
                <div className="office-box my-3 my-md-0">
                  <div>
                    <img src="/images/icon-office.svg" />
                  </div>
                  <div>Office</div>
                </div>
              </Col>
              <Col xs={{ size: 12, order: 3 }} md={{ size: 5, order: 2 }}>
                <h5>Request to Join Firm</h5>
                <h3>{firm.name}</h3>
              </Col>
              <Col
                xs={{ size: 12, order: 1 }}
                md={{ size: 2, order: 3 }}
                className="text-right"
              >
                <button
                  className="btn-wgt small danger"
                  type="button"
                  onClick={toggleRequestModal}
                >
                  Close
                </button>
              </Col>
            </Row>

            {formError && (
              <div className="d-flex justify-content-center mt-3">
                <div className="alert alert-danger w-50">
                  {formError.split(',').map((item: string, key: number) => (
                    <p className="text-body my-2" key={key}>
                      {item}
                    </p>
                  ))}
                </div>
              </div>
            )}

            <Row>
              <Col lg="8">
                <Row>
                  <Col lg="6">
                    <SimpleInput
                      field="first_name"
                      label="First Name"
                      column={9}
                      required={true}
                      defaultValue={userData?.first_name}
                    />
                    <SimpleInput
                      field="email"
                      label="Email"
                      column={9}
                      required={true}
                      defaultValue={userData?.email}
                    />
                  </Col>
                  <Col lg="6">
                    <SimpleInput
                      field="last_name"
                      label="Last Name"
                      column={9}
                      required={true}
                      defaultValue={userData?.last_name}
                    />

                    <FormGroup row={true}>
                      <Label
                        htmlFor="business_phone-field"
                        className="col-12 col-md-3 col-form-label"
                        xs="12"
                        md="3"
                      >
                        Direct Phone
                      </Label>
                      <div className="col-12 col-md-6 pr-3 pr-md-0">
                        <Input
                          type="text"
                          name="business_phone"
                          id="business_phone-field"
                          innerRef={register({
                            required: true,
                          })}
                          invalid={Boolean(errors?.office_phone)}
                          placeholder="eg. 1+ 123 123 1234"
                        />
                      </div>
                      <div className="col-12 col-md-3 mt-3 mt-md-0">
                        <Input
                          type="text"
                          name="business_phone_extension"
                          id="business_phone_extension-field"
                          innerRef={register}
                          placeholder="eg. 123"
                        />
                      </div>
                    </FormGroup>

                    <SimpleInput
                      field="business_mobile"
                      label="Office Mobile"
                      column={9}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={{ size: 12 }}
                    lg={{ size: 11, offset: 1 }}
                    className="provide-message pr-0 pl-0 pl-lg-2 pl-xl-3"
                  >
                    <div className="ml-0 ml-lg-3 mb-3 mb-lg-0">
                      <SimpleInput
                        field="message"
                        label="Provide Message"
                        type="textarea"
                        rows="3"
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg="4" className="d-flex flex-column">
                <div>
                  {!!firm.offices?.length && (
                    <SelectOfficeCard
                      options={firm.offices}
                      field="office"
                      placeholder="Choose an office"
                    />
                  )}
                </div>

                <div className="mt-auto text-right ">
                  <button type="submit" className="btn-wgt primary small">
                    Send Request
                    <br />
                    to Join
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
}
