import React from 'react';

import Footer from '../../components/Footer';
import Menu from '../../components/Menu';
import IndexContent from './Content';
import './styles.scss';

const Index: React.FC = () => {
  return (
    <div className="index-page">
      <Menu />
      <IndexContent />
      <Footer />
    </div>
  );
};

export default Index;
