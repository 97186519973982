import React from 'react';
import { Col, Row } from 'reactstrap';
import classnames from 'classnames';

const newServices = {
  first: [
    'Laboratory Services',
    'Lapidary - gemstone cutter',
    'Diamond cutter',
    'Carver',

    'Drill',
  ],
  second: [
    'Manufacture',
    'Metalsmith',
    'Mold Maker',
    'Enameler',
    'Engraver',
    'Refiner',
  ],
  third: ['Designer Drawing', 'Designer CAD/CAM', 'Pearl Stringer', 'image'],
};
const WorkOrderServices: React.FC = () => {
  return (
    <>
      <div className="verySmallHide">
        <Row>
          <Col>
            {newServices.first.map((service, key) => (
              <React.Fragment key={`service-${service}-${key}`}>
                {service !== 'image' ? (
                  <p
                    className={classnames({
                      ipadProPadding: service === 'Lapidary - gemstone cutter',
                    })}
                  >
                    {service}
                  </p>
                ) : (
                  <div className="work-orders-services-bg" />
                )}
              </React.Fragment>
            ))}
          </Col>
          <Col className="services-second-column">
            {newServices.second.map((service, key) => (
              <React.Fragment key={`service-${service}-${key}`}>
                {service !== 'image' ? (
                  <p>{service}</p>
                ) : (
                  <div className="work-orders-services-bg" />
                )}
              </React.Fragment>
            ))}
          </Col>
          <Col className="services-third-column">
            {newServices.third.map((service, key) => (
              <React.Fragment key={`service-${service}-${key}`}>
                {service !== 'image' ? (
                  <p>{service}</p>
                ) : (
                  <div className="work-orders-services-bg" />
                )}
              </React.Fragment>
            ))}
          </Col>
        </Row>
      </div>
      <div className="verySmallShow">
        <Row>
          {[
            ...newServices.first,
            ...newServices.second,
            ...newServices.third,
          ].map((service, key) => (
            <Col
              xs="6"
              key={`service-${service}-${key}`}
              style={{
                position: 'relative',
                top: service === 'Designer CAD/CAM' ? -20 : 0,
              }}
            >
              {service !== 'image' ? (
                <p>{service}</p>
              ) : (
                <div className="work-orders-services-bg" />
              )}
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default WorkOrderServices;
