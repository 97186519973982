import React, { useMemo } from 'react';
import { Modal } from 'reactstrap';
import LoginModalContent from '../LoginModalContent';
import { useHistory, useLocation } from 'react-router-dom';

import LoginButton from './LoginButton';

const locationHash = ['#login', '#forgotPassword'];
const LoginModal: React.FC = () => {
  const location = useLocation();
  const history = useHistory();

  const isModalOpen = useMemo(() => locationHash.includes(location.hash), [
    location,
    locationHash,
  ]);

  const toggle = () =>
    history.push(`${location.pathname}${!isModalOpen ? location.hash : ''}`);

  return (
    <>
      <LoginButton />
      <Modal
        isOpen={isModalOpen}
        toggle={toggle}
        scrollable={false}
        modalClassName="login-modal"
        className="login-modal-content"
        fade={false}
        autoFocus
      >
        <LoginModalContent toggle={toggle} />
      </Modal>
    </>
  );
};

export default LoginModal;
