import React from 'react';
import { Modal } from 'reactstrap';
interface ModalDetailsProps {
  modalContent?: string | null;
  setModalContent: React.Dispatch<React.SetStateAction<string | null>>;
}
const ModalDetails: React.FC<ModalDetailsProps> = ({
  modalContent,
  setModalContent,
}) => {
  return (
    <Modal
      isOpen={!!modalContent}
      toggle={() => setModalContent(null)}
      backdrop={false}
      keyboard
    >
      <img src={`/images/${modalContent}`} />
    </Modal>
  );
};

export default ModalDetails;
