import React, { useContext, useState } from 'react';

export interface SearchResult {
  type: 'invoice' | 'aml' | 'trace';
}
interface InvoicesContextData {
  active?: SearchResult;
  setActive(result: SearchResult): void;
}

export const InvoicesContext = React.createContext<InvoicesContextData>(
  {} as InvoicesContextData,
);

export const InvoicesProvider: React.FC = ({ children }) => {
  const [active, setActive] = useState<SearchResult>();
  const contextValues: InvoicesContextData = { active, setActive };

  return (
    <InvoicesContext.Provider value={contextValues}>
      {children}
    </InvoicesContext.Provider>
  );
};

export default function userInvoicesContext(): InvoicesContextData {
  return useContext(InvoicesContext);
}
