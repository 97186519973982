import React, { useMemo } from 'react';
import { FaUser } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';
import useAuthenticationCookies from '@wgt/authentication/useAuthenticationCookies';
import { CRM_URL } from '../../utils/constants';

const LoginButton: React.FC = () => {
  const [active] = useAuthenticationCookies();

  const history = useHistory();

  const isLogged = useMemo(() => active.access_token, [active, history]);

  if (isLogged) {
    return (
      <a
        href={CRM_URL}
        target="_blank"
        rel="noreferrer"
        className="btn btn-primary"
      >
        Access <FaUser />
      </a>
    );
  }
  return (
    <Link to={'#login'} className="btn btn-primary">
      Login <FaUser />
    </Link>
  );
};

export default LoginButton;
