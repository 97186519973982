import React, { useContext, useState, useMemo } from 'react';
import { SiteFirm, EmployeeJoinRequest } from '@wgt/types';
import { useLazyQuery } from '@apollo/client';
import { FIRM_EXISTS } from '../services/gql/account';

interface UserSaved {
  name: string;
  email: string;
}

interface SiteFirmFilterInput {
  name?: string;
  email?: string;
  website?: string;
}

interface UserDataInput {
  first_name?: string;
  last_name?: string;
  email?: string;
}

export interface SiteFirmFilterInputVariables {
  variables: {
    filter: SiteFirmFilterInput;
  };
}

interface RegisterContextData {
  firmSaved?: UserSaved;
  setFirmSaved(user?: UserSaved): void;
  requestSent?: EmployeeJoinRequest;
  setRequestSent(user?: EmployeeJoinRequest): void;
  fetchListFirm(variables: SiteFirmFilterInputVariables): void;
  listFirm?: SiteFirm[];
  showListFirm?: boolean;
  setShowListFirm(arg: boolean): void;
  userData?: UserDataInput;
  setUserData(user?: UserDataInput): void;
}

export const RegisterContext = React.createContext<RegisterContextData>(
  {} as RegisterContextData,
);

export const RegisterProvider: React.FC = ({ children }) => {
  const [firmSaved, setFirmSaved] = useState<UserSaved | undefined>();
  const [requestSent, setRequestSent] = useState<
    EmployeeJoinRequest | undefined
  >();
  const [showListFirm, setShowListFirm] = useState<boolean>(false);
  const [userData, setUserData] = useState<UserDataInput | undefined>();

  const [fetchListFirm, { data: dataListFirm }] = useLazyQuery<{
    _firmExists: { data: SiteFirm[] };
  }>(FIRM_EXISTS, {
    onCompleted: () => setShowListFirm(true),
  });

  const listFirm = useMemo(() => dataListFirm?._firmExists?.data ?? [], [
    dataListFirm?._firmExists?.data,
  ]);

  const contextValues: RegisterContextData = {
    firmSaved,
    setFirmSaved,
    requestSent,
    setRequestSent,
    fetchListFirm,
    listFirm,
    showListFirm,
    setShowListFirm,
    userData,
    setUserData,
  };
  return (
    <RegisterContext.Provider value={contextValues}>
      {children}
    </RegisterContext.Provider>
  );
};

export default function useRegisterContext(): RegisterContextData {
  return useContext(RegisterContext);
}
