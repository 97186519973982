import React from 'react';
import { Col, Row, FormGroup, Label, Input } from 'reactstrap';
import { useFormContext, Controller } from 'react-hook-form';
import { Country } from '@wgt/types';
import { COUNTRIES } from '../../services/gql/common';
import SimpleInput from '../SimpleInput';
import SelectSearch from '../SelectSearch';
// import useRegisterContext from '../../hooks/useRegister';

interface Props {
  signUpLoading: boolean;
  formError: string;
}

export default function CreateProfile({
  signUpLoading,
  formError,
}: Props): JSX.Element {
  const { register, errors } = useFormContext();

  // This method will be hidden in the early access launch
  // will be enabled later
  // const { fetchListFirm } = useRegisterContext();

  // const onBlur = (e: ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   const field = name.substring(name.indexOf('[') + 1, name.indexOf(']'));
  //   fetchListFirm({
  //     variables: {
  //       filter: {
  //         [field]: `%${value}%`,
  //       },
  //     },
  //   });
  // };

  return (
    <>
      <section className="mt-5 create-profile-form">
        <h3 className="highlight mb-0 border-bottom pb-2">Create Profile</h3>

        <div className="text-center mt-4">
          <button type="button" className="btn-tab-blue active no-cursor">
            Company Login
          </button>
        </div>

        {formError && (
          <div className="d-flex justify-content-center mt-3">
            <div className="alert alert-danger w-50">
              {formError.split(',').map((item: string, key: number) => (
                <p className="text-body my-2" key={key}>
                  {item}
                </p>
              ))}
            </div>
          </div>
        )}

        <Row className="mt-5 mt-lg-4 no-gutters">
          <Col xs="12" lg="4" className="pr-0 pr-md-3">
            <SimpleInput
              field="name"
              label="Company"
              column={9}
              required
              // onBlur={onBlur}
              prefix="firm"
            />
            <SimpleInput
              field="mobile"
              label="Mobile"
              column={9}
              required
              prefix="user"
            />

            <FormGroup row={true}>
              <Label
                htmlFor="business_phone-field"
                className="col-12 col-md-3 col-form-label align-self-center d-flex"
                xs="12"
                md="3"
              >
                Direct Phone
              </Label>
              <div className="col-12 col-md-5 pr-1 mb-lg-0">
                <Input
                  type="text"
                  name="user.business_phone"
                  id="business_phone-field"
                  innerRef={register}
                  invalid={Boolean(errors?.office?.business_phone)}
                />
              </div>
              <Label
                htmlFor="ext-field"
                xs="12"
                md="1"
                className="align-self-center d-flex justify-content-start justify-content-lg-end pl-md-1"
              >
                Ext.
              </Label>
              <div className="col-12 col-md-3">
                <Input
                  type="text"
                  name="user.business_phone_extension"
                  innerRef={register}
                />
              </div>
            </FormGroup>

            <SimpleInput
              field="email"
              label="Email"
              column={9}
              required
              // onBlur={onBlur}
              prefix="firm"
            />
          </Col>
          <Col xs="12" lg="4" className="pr-0 pr-md-3">
            <SimpleInput
              field="firm.address.address"
              label="Address 1"
              required
              column={9}
            />
            <SimpleInput
              field="firm.address.address_2"
              label="Address 2"
              column={9}
            />
            <FormGroup row={true}>
              <Label xs="12" md="3">
                Country
              </Label>
              <Col xs="12" md="9" className="select-search">
                <Controller
                  name="firm.address.country"
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                  render={({ onChange }) => (
                    <SelectSearch
                      gql={COUNTRIES}
                      getOptionLabel={(e: Country) => `${e.name ?? ''} `}
                      getOptionValue={(e: Country) => `${e.id}`}
                      name="name"
                      placeholder="Country"
                      onChange={onChange}
                      defaultInputValue=""
                      className="select-search"
                      invalid={errors?.firm?.address?.country}
                    />
                  )}
                />
              </Col>
            </FormGroup>

            <SimpleInput
              field="firm.address.state"
              label="State/Province"
              required
              column={9}
            />

            <SimpleInput
              field="firm.address.city"
              label="City"
              required
              column={9}
            />

            <SimpleInput
              field="firm.address.postal_code"
              label="Zip/Postal code"
              required
              column={9}
            />
          </Col>

          <Col xs="12" lg="4" className="d-flex flex-column pr-0 pr-md-3">
            <SimpleInput
              field="firm.address.alt_phone"
              label="Total Free Phone"
              column={9}
            />
            <SimpleInput
              field="firm.address.phone"
              label="Local Phone"
              column={9}
            />
            <SimpleInput
              field="website"
              label="Website"
              column={9}
              // onBlur={onBlur}
              prefix="firm"
            />
            <div className="mt-auto">
              <SimpleInput
                label="Office Location Name"
                field="name"
                required
                column={9}
                prefix="office"
              />
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <button
            type="submit"
            className="btn-wgt primary small d-flex align-items-stretch"
            disabled={signUpLoading ?? false}
          >
            {signUpLoading && (
              <div className="d-flex align-items-center mr-3">
                <span className="spinner-border spinner-border-sm text-light" />
              </div>
            )}
            <div>
              Request to
              <br /> Active Account
            </div>
          </button>
        </div>
      </section>
    </>
  );
}
