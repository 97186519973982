/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import Select, { OptionProps, components, ControlProps } from 'react-select';
import { IconButton, Pane, Paragraph, Strong } from 'evergreen-ui';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import classnames from 'classnames';
import './style.scss';

export interface OfficeOption {
  id: number;
  name: string;
  address: string;
  state: string;
  phone: string;
  country: {
    name: string;
  };
}

interface SelectCardProps {
  title?: string;
  options?: OfficeOption[];
  field: string;
  isMulti?: boolean;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
}

type CustomOptionType = OptionProps<OfficeOption>;

const CustomOption: React.FC<CustomOptionType> = ({
  isDisabled,
  isSelected,
  data,
  selectOption,
  clearValue,
}) => {
  return (
    <div className="d-flex m-1 justify-content-between align-items-center p-1">
      <div className="d-flex flex-column">
        <p className="mb-0">{data.name}</p>
        {!!data.phone && <p className="muted mb-0">{data.phone}</p>}
      </div>
      <button
        className={classnames('btn-wgt small', {
          danger: isSelected,
          primary: !isSelected,
        })}
        onClick={() => (isSelected ? clearValue() : selectOption(data))}
        disabled={isDisabled}
        type="button"
      >
        {isSelected ? 'remove' : 'add'}
      </button>
    </div>
  );
};

const SelectContext = React.createContext<
  { placeholder?: string; toggleMenu(): void } | any
>({});

const CustomControl: React.FC<ControlProps<CustomOptionType>> = (props) => {
  const { toggleMenu, placeholder } = React.useContext(SelectContext);
  const { hasValue, menuIsOpen, getValue } = props;

  const value = useMemo(() => {
    if (!hasValue) {
      return undefined;
    }
    const val = getValue();
    return val ? val[0] : undefined;
  }, [hasValue, menuIsOpen]);

  return (
    <Pane>
      <Pane display="flex" justifyContent="space-between">
        <h4 className="ml-2 mb-1">
          {!menuIsOpen && hasValue
            ? value?.name
            : placeholder ?? 'Choose an option'}
        </h4>
        <IconButton
          icon={menuIsOpen ? FaChevronUp : FaChevronDown}
          type="button"
          appearance="minimal"
          onClick={toggleMenu}
        />
      </Pane>
      {hasValue && !menuIsOpen && value ? (
        <Pane paddingX="10px" paddingBottom="10px">
          <Paragraph marginY="10px">
            Phone: <Strong>{value.phone ?? 'N/A'}</Strong>
          </Paragraph>
          <Paragraph>{value.address}</Paragraph>
          <Paragraph>
            <Strong>{value.state}</Strong>
          </Paragraph>
          <Paragraph>{value.country?.name}</Paragraph>
        </Pane>
      ) : (
        <components.Control {...props} />
      )}
    </Pane>
  );
};

export default function SelectCard({
  title,
  options,
  field,
  isMulti,
  placeholder,
  disabled,
  required,
}: SelectCardProps): JSX.Element {
  const [isMenuOpen, setMenuOpen] = React.useState(false);
  const toggleMenu = () => setMenuOpen((x) => !x);

  return (
    <SelectContext.Provider value={{ toggleMenu, placeholder }}>
      <div className="w-100 select-card">
        {!!title && <h4 className="ml-2 mb-1">{title}</h4>}
        <div className="card border">
          <div className="card-body p-2">
            <Controller
              isClearable
              as={Select}
              isMulti={isMulti}
              closeMenuOnSelect={!isMulti}
              options={options}
              noOptionsMessage={() => 'No options'}
              name={field}
              isDisabled={disabled}
              rules={{ required }}
              getOptionLabel={(e: { name: string }) => e.name}
              getOptionValue={(e: { id: string }) => e.id}
              onMenuOpen={() => setMenuOpen(true)}
              onMenuClose={() => setMenuOpen(false)}
              components={{
                Option: CustomOption,
                Control: CustomControl,
                DropdownIndicator: undefined,
              }}
              styles={{
                placeholder: (base: any) => ({
                  ...base,
                  fontSize: '14px',
                  whiteSpace: 'nowrap',
                }),
                input: (base: any) => ({
                  ...base,
                  fontSize: '14px',
                }),
                valueContainer: (base: any) => ({
                  ...base,
                  padding: '2px 4px',
                }),
              }}
              menuIsOpen={isMenuOpen}
              placeholder={placeholder}
            />
          </div>
        </div>
      </div>
    </SelectContext.Provider>
  );
}
