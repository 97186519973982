import React from 'react';
import Footer from '../../components/Footer';
import Menu from '../../components/Menu';
import WorkOrdersContent from './WorkOrdersContent';
import './styles.scss';

const WorkOrders: React.FC = () => {
  return (
    <div className="work-orders-page">
      <Menu />
      <WorkOrdersContent />
      <Footer />
    </div>
  );
};

export default WorkOrders;
