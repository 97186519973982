import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import classnames from 'classnames';
import Loader from '../Loader';

interface CustomInputGroupProps {
  buttonLabel: string;
  field: string;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  placeholder?: string;
  required?: boolean;
  registerProps?: Record<string, unknown>;
  active?: boolean;
  isLoading?: boolean;
}
const CustomInputGroup: React.FC<CustomInputGroupProps> = ({
  buttonLabel,
  placeholder,
  field,
  required,
  onClick,
  registerProps,
  active,
  isLoading,
}) => {
  const { register, errors } = useFormContext();
  const hasError = !!errors && !!errors[field];
  return (
    <InputGroup className={classnames('with-button', { active })}>
      <Input
        placeholder={placeholder}
        innerRef={register({ ...registerProps, required })}
        name={field}
        invalid={hasError}
      />
      <InputGroupAddon addonType="append">
        <Button color="secondary" onClick={onClick} disabled={isLoading}>
          <span>{isLoading ? <Loader small rounded /> : buttonLabel}</span>
        </Button>
      </InputGroupAddon>
    </InputGroup>
  );
};

export default CustomInputGroup;
