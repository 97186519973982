import React from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../../components/Footer';
import Menu from '../../components/Menu';
import RegisterContent from './RegisterContent';
import RegisterForm from './RegisterForm';
import './styles.scss';

const Register: React.FC = () => {
  const params = useParams<{ type: string }>();

  return (
    <div className="register-page">
      <Menu />

      <div className="container-lg">
        {!!params?.type ? <RegisterContent /> : <RegisterForm />}
      </div>
      <Footer />
    </div>
  );
};

export default Register;
