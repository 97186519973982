import React, { useState } from 'react';
import { Row } from 'reactstrap';
import userInvoicesContext from '../../hooks/userInvoicesContext';
import IndexComponentItem from './IndexComponentItem';
import ModalDetails from './ModalDetails';
import './styles.scss';
export interface IndexComponent {
  color: string;
  title: string;
  description: string;
  details: string;
  type: string;
}
const COMPONENTS: IndexComponent[] = [
  {
    color: '#FFECC7',
    title: 'Invoice',
    description: 'Manage with Point of Sales',
    details: 'invoice_review.jpg',
    type: 'invoice',
  },
  {
    color: '#C7EBFF',
    title: 'AML Statement',
    description: 'Payments and Schedules',
    details: '/invoices',
    type: 'aml',
  },
  {
    color: '#C7FFE4',
    title: 'Trace Documents',
    description: 'Merge with Reports',
    details: '/invoices',
    type: 'trace',
  },
];

const IndexComponents: React.FC = () => {
  const [modalContent, setModalContent] = useState<string | null>(null);
  const { active } = userInvoicesContext();

  return (
    <>
      <Row className="mt-5 index-components">
        {COMPONENTS.map((component, key) => (
          <IndexComponentItem
            key={`index-component-${key}`}
            component={component}
            setModalContent={setModalContent}
            isActive={active?.type === component.type}
          />
        ))}
      </Row>
      <ModalDetails
        modalContent={modalContent}
        setModalContent={setModalContent}
      />
    </>
  );
};

export default IndexComponents;
