import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

export default function ContactBox(): JSX.Element {
  return (
    <Card body inverse className="contact-box mb-4 mb-md-3">
      <CardBody className="p-0">
        <Row>
          <Col
            xs={{ size: 12, order: 0 }}
            sm={{ size: 6, order: 0 }}
            md={{ size: 12, order: 0 }}
            lg={{ size: 5, order: 0 }}
            xl={{ size: 6, order: 0 }}
          >
            <p className="margin-text-left-regular">
              <strong>Contact</strong>
            </p>
          </Col>
          <Col
            xs={{ size: 12, order: 3 }}
            sm={{ size: 6, order: 1 }}
            md={{ size: 12, order: 3 }}
            lg={{ size: 7, order: 1 }}
            xl={{ size: 6, order: 1 }}
            className="mt-3 mt-sm-0 mt-md-3 mt-lg-0"
          >
            <p>8605 Santa Monica Blvd #89281</p>
          </Col>

          <Col
            xs={{ size: 12, order: 1 }}
            sm={{ size: 6, order: 2 }}
            md={{ size: 12, order: 1 }}
            lg={{ size: 5, order: 2 }}
            xl={{ size: 6, order: 2 }}
          >
            <p className="margin-text-left-regular">+1 (213) 986-3526</p>
          </Col>
          <Col
            xs={{ size: 12, order: 4 }}
            sm={{ size: 6, order: 3 }}
            md={{ size: 12, order: 4 }}
            lg={{ size: 7, order: 3 }}
            xl={{ size: 6, order: 3 }}
          >
            <p>Los Angeles CA 90069-4109 USA</p>
          </Col>

          <Col
            xs={{ size: 12, order: 2 }}
            sm={{ size: 6, order: 4 }}
            md={{ size: 12, order: 2 }}
            lg={{ size: 5, order: 4 }}
            xl={{ size: 6, order: 4 }}
          >
            <p>
              <img
                src="/images/whatsapp-icon.png"
                width="22"
                className="margin-text-right-small"
              />
              +1 (604) 512-1970
            </p>
          </Col>
          <Col
            xs={{ size: 12, order: 5 }}
            sm={{ size: 6, order: 5 }}
            md={{ size: 12, order: 5 }}
            lg={{ size: 7, order: 5 }}
            xl={{ size: 6, order: 5 }}
          >
            <p>info@worldgemtrade.com</p>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
