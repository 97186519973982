import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import NewsletterTypeList from '../NewsletterTypeList';
import SimpleInput from '../SimpleInput';
import ToolTipBuyingPublic from '../ToolTipBuyingPublic';
import { COUNTRIES } from '../../services/gql/common';
import SelectSearch from '../SelectSearch';
import { Country } from '@wgt/types';
import userNewsletterContext from '../../hooks/useNewsletter';

interface Props {
  updateLoading: boolean;
}

export default function NewsletterExtraFieldsForm({
  updateLoading,
}: Props): JSX.Element {
  const { register, reset } = useFormContext();
  const { setExtraOpen } = userNewsletterContext();

  const overlayAction = () => {
    reset({
      category_ids: [],
      office_types: [],
    });
    setExtraOpen(false);
  };

  return (
    <>
      <div className="background-overlay" onClick={overlayAction} />
      <div className="newsletter-extra-fields row">
        <Col>
          <div className="newsletter-extra-fields-conent container">
            <div>
              <Row>
                <Col xs="12" sm="12" md="8" className="m-mb-2">
                  Thank you for subscribing to the e-Newsletter of World Gem
                  Trade.
                </Col>
                <Col className="text-right m-mb-2">
                  You Are Buying Public{' '}
                  <ToolTipBuyingPublic
                    text="Client does not have a business in the jewelry industry"
                    className="mx-2"
                  />
                  <input
                    type="checkbox"
                    ref={register}
                    name="buying_public"
                    style={{ position: 'relative', top: 3 }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>Your contact information</Col>
              </Row>
              <Row className="mt-2">
                <Col md="8" lg="7">
                  <Row>
                    <Col xs="12" sm="12" md="4">
                      <SimpleInput
                        field="name"
                        placeholder="First and Last Name"
                      />
                    </Col>
                    <Col xs="12" sm="12" md="4">
                      <SimpleInput field="address" placeholder="Address 1" />
                    </Col>
                    <Col xs="12" sm="12" md="4">
                      <SimpleInput
                        field="state"
                        placeholder="State / Province"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" sm="12" md="4">
                      <SimpleInput
                        field="company_name"
                        placeholder="Company Name"
                      />
                    </Col>
                    <Col xs="12" sm="12" md="4">
                      <SimpleInput field="address_2" placeholder="Address 2" />
                    </Col>
                    <Col xs="12" sm="12" md="4">
                      <Controller
                        name="country"
                        defaultValue=""
                        render={({ onChange }) => (
                          <SelectSearch
                            gql={COUNTRIES}
                            getOptionLabel={(e: Country) => `${e.name ?? ''} `}
                            getOptionValue={(e: Country) => `${e.id}`}
                            name="name"
                            placeholder="Country"
                            onChange={onChange}
                            defaultInputValue=""
                            className="select-country"
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" sm="12" md="4">
                      <SimpleInput
                        field="phone"
                        placeholder="(+) Phone"
                        className="input-no-margin-bottom"
                      />
                    </Col>
                    <Col xs="12" sm="12" md="4">
                      <SimpleInput
                        field="city"
                        placeholder="City"
                        className="input-no-margin-bottom"
                      />
                    </Col>
                    <Col xs="12" sm="12" md="4">
                      <SimpleInput
                        field="postal_code"
                        placeholder="Zip / Postal Code"
                        className="input-no-margin-bottom"
                      />
                    </Col>
                  </Row>
                </Col>

                <Col className="d-flex flex-column align-items-end justify-content-end">
                  Do you own or work for a Business in the Jewerly Industry?
                  <div className="newsletter-type-list-margins">
                    <NewsletterTypeList popoverId="newsletter" />
                  </div>
                  <button
                    type="submit"
                    className="btn-wgt primary small btn-send d-flex align-items-stretch"
                    disabled={updateLoading ?? false}
                  >
                    {updateLoading && (
                      <div className="d-flex align-items-center mr-2">
                        <span className="spinner-border spinner-border-sm text-light" />
                      </div>
                    )}
                    <div className="d-flex align-items-center">Send</div>
                  </button>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </div>
    </>
  );
}
