import { gql } from '@apollo/client';

export const COUNTRIES = gql`
  query countries($filter: CountryFilterInput) {
    countries(first: 1000, filter: $filter) {
      data {
        id
        name
      }
    }
  }
`;
