import React from 'react';
import { Col, Row } from 'reactstrap';
import './styles.scss';

const ServiceFee: React.FC = () => {
  return (
    <>
      <Row className="mt-5">
        <Col>
          <h3 className="mt-5 highlight">Service Fee</h3>
        </Col>
      </Row>
      <Row className="service-fee mt-5" noGutters>
        <Col sm="6" md="3">
          <p>Point of Sales</p>
        </Col>
        <Col sm="6" md="3" className="break-line-ipad">
          <p className=" showMobile">Base: 10 Cents USD per Invoice</p>
          <p className=" hideMobile">
            Base: 10 Cents USD
            <br /> per Invoice
          </p>
        </Col>
        <Col sm={{ offset: 0, size: 6 }} md={{ size: 3, offset: 3 }}>
          <p className="ml-md-3 showMobile">
            0.1% of Payment - Returns Refunded
          </p>
          <p className="ml-md-3 hideMobile">
            0.1% of Payment -
            <br /> Returns Refunded
          </p>
        </Col>
        <Col />
      </Row>
      <Row className="service-fee" noGutters>
        <Col sm="6" md="3">
          <p>Laboratory Report Creation</p>
        </Col>

        <Col sm="6" md="3" className="break-line-ipad">
          <p className=" showMobile">Base: 10 Cents USD per Invoice</p>
          <p className=" hideMobile">
            Base: 10 Cents USD
            <br /> per Invoice
          </p>
        </Col>
        <Col sm="6" md="3">
          <p>50 Cents USD per Report</p>
        </Col>
        <Col sm="6" md="3">
          <p className="ml-md-3 showMobile">
            0.1% of Payment - Returns Refunded
          </p>
          <p className="ml-md-3 hideMobile">
            0.1% of Payment -
            <br /> Returns Refunded
          </p>
        </Col>
      </Row>
      <Row className="service-fee" noGutters>
        <Col sm="6" md="3">
          <p>Website / App Development</p>
        </Col>
        <Col sm={{ offset: 0, size: 6 }} md={{ size: 3, offset: 3 }}>
          <p>Please Ask</p>
        </Col>
        <Col />
        <Col />
      </Row>
    </>
  );
};

export default ServiceFee;
