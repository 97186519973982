import React from 'react';
import { emailRegex } from '../../utils/constants';

import CustomInputGroup from '../CustomInputGroup';

import './styles.scss';
const Newsletter: React.FC<{
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  active?: boolean;
  isLoading?: boolean;
}> = ({ onClick, active, isLoading }) => {
  return (
    <CustomInputGroup
      buttonLabel="e-Newsletter"
      placeholder="Enter your email "
      field="email"
      registerProps={{ pattern: emailRegex }}
      onClick={onClick}
      active={active}
      isLoading={isLoading}
      required
    />
  );
};

export default Newsletter;
