import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import { useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import NewsletterTypeList from '../NewsletterTypeList';

interface Props {
  setSection: (section: number) => void;
  section: number;
}

export default function FirmTypes({ setSection, section }: Props): JSX.Element {
  const methods = useFormContext();
  const { errors } = methods;

  return (
    <Container className="pr-4 pr-lg-5 firm-types-container">
      <Row className="mt-3">
        <Col xs="12" lg="12" className="types-form">
          <div className="">
            <div className="d-flex align-items-center justify-content-start justify-content-lg-end mb-2">
              <div
                className={classnames({
                  'border border-danger p-2': errors?.own_company,
                })}
              >
                {errors?.own_company && (
                  <div>
                    <p className="text-danger">
                      Please select the field below to continue
                    </p>
                  </div>
                )}
                <div className="d-flex mt-3 mt-lg-5">
                  <label className="mb-0 mr-2 large" htmlFor="own-company">
                    Do you own or work for a Business in the Jewerly Industry?
                  </label>
                  <div className="button-selector">
                    <button
                      type="button"
                      className={classnames({
                        selected: section === 1,
                      })}
                      onClick={() => [section === 0 && setSection(1)]}
                    >
                      YES
                    </button>
                    <button type="button">NO</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-lg-end firm-types-dropdown-popover">
              <label className="mb-0 mr-4 mb-2 large">
                Please describe your organization.
              </label>

              <NewsletterTypeList
                popoverId="register"
                isNewsletter={false}
                prefix="office"
                fieldNameTypes="types"
              />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
