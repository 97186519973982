import React, { useMemo, useState } from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import { FaAngleDown } from 'react-icons/fa';
import { useQuery } from '@apollo/client';
import { useFormContext } from 'react-hook-form';
import ItemsSelect from './ItemsSelect';
import {
  GET_OFFICE_TYPES_GQL,
  GET_OFFICE_CATEGORIES_GQL,
} from '../../services/gql/office';
import './styles.scss';
import { firmTypesToMap, FIRM_TYPES_MOBILE } from '../../utils/constants';
import { OfficeType } from '../FirmTypeList/FirmTypes';

interface FirmTypeListProps {
  prefix?: string;
  popoverId?: string;
  onClick?: (field: string) => void;
  isNewsletter?: boolean;
  fieldNameTypes?: string;
  fieldNameCategories?: string;
}

interface OfficeCategory {
  id: number;
  name: string;
}

interface OfficeTypes {
  id?: string;
  name: string;
}

const NewsletterTypeList: React.FC<FirmTypeListProps> = ({
  popoverId,
  onClick,
  isNewsletter,
  prefix,
  fieldNameTypes = 'office_types',
  fieldNameCategories = 'category_ids',
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);

  const { watch } = useFormContext();

  const fieldTypes = prefix ? `${prefix}.${fieldNameTypes}` : fieldNameTypes;
  const fieldCategories = prefix
    ? `${prefix}.${fieldNameCategories}`
    : fieldNameCategories;

  const watchValues = watch([fieldTypes, fieldCategories]);
  const officeTypes = watchValues?.[fieldTypes];
  const categoryIds = watchValues?.[fieldCategories];

  const { data: dataOfficeType } = useQuery(GET_OFFICE_TYPES_GQL);

  const { data: dataOfficeCategory } = useQuery(GET_OFFICE_CATEGORIES_GQL);

  const types = useMemo(() => {
    const typesSerialized = dataOfficeType?.__type?.enumValues
      ? dataOfficeType?.__type?.enumValues
          ?.map((value: OfficeTypes) => ({
            id: value.name,
            name: value.name.split('_').join(' '),
          }))
          .filter((filtered: OfficeType) =>
            firmTypesToMap
              .map((item) => item.toLowerCase())
              .includes(filtered.name),
          )
      : [];

    return !isNewsletter
      ? typesSerialized
      : typesSerialized?.filter(
          (value: OfficeTypes) =>
            !!value.id && FIRM_TYPES_MOBILE.offices.includes(value.id),
        );
  }, [dataOfficeType?.__type?.enumValues]);

  const categories = useMemo(() => {
    const categorySerialized = dataOfficeCategory?._categories?.data ?? [];

    return !isNewsletter
      ? categorySerialized
      : categorySerialized?.filter(
          (value: OfficeCategory) =>
            !!value.id && FIRM_TYPES_MOBILE.categories.includes(value.name),
        );
  }, [dataOfficeCategory]);

  const itemsSelected = useMemo(() => {
    const typesSelected = officeTypes.length
      ? types
          .filter((type: OfficeTypes) => officeTypes.includes(type.id))
          .map((type: OfficeTypes) => type.name)
      : [];
    const categoriesSelected = categoryIds?.length
      ? categories
          .filter((category: OfficeCategory) =>
            categoryIds?.includes(category.id),
          )
          .map((category: OfficeCategory) => category.name)
      : [];
    return typesSelected.concat(categoriesSelected).join(', ');
  }, [officeTypes.length, categoryIds.length]);

  return (
    <div>
      <div
        id={`popover-${popoverId}`}
        onClick={toggle}
        className="popover-open-button"
      >
        {itemsSelected ? (
          <span className="text-capitalize text-truncate">{itemsSelected}</span>
        ) : (
          <span>Description</span>
        )}
        <FaAngleDown />
      </div>
      <Popover
        placement="bottom"
        trigger="legacy"
        isOpen={popoverOpen}
        target={`popover-${popoverId}`}
        toggle={toggle}
        innerClassName="popover-holder"
      >
        <PopoverBody>
          <ItemsSelect
            items={categories}
            field={fieldCategories}
            onClick={onClick}
            popoverId={popoverId}
          />
          <br />
          <ItemsSelect
            items={types}
            field={fieldTypes}
            onClick={onClick}
            popoverId={popoverId}
          />
        </PopoverBody>
      </Popover>
    </div>
  );
};

export default NewsletterTypeList;
