import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import WorkOrderServices from './WorkOrderServices';

const WorkOrdersContent: React.FC = () => {
  return (
    <Container>
      <Row className="my-5">
        <Col>
          <h1>Supported Services</h1>
          <p className="muted ml-5">
            Tracing of resources with work order management.
          </p>
        </Col>
      </Row>

      <Row>
        <Col sm="12" md="5" className="m-mb-5">
          <h3 className="mb-5 highlight">Work Orders</h3>
          <img src="/images/appraisalReportsHero.png" width="100%" />
        </Col>
        <Col sm="12" md="7">
          <h3 className="highlight">Trade With A Trace</h3>
          <p className="muted ml-5 mb-5">
            Trace to ensure you attain value for your business.
          </p>

          <WorkOrderServices />
        </Col>
      </Row>
      <div className="showMobile">
        <Row className="my-3">
          <Col sm="12" md="5">
            <img
              src="/images/traceDocumentsHero.png"
              width="100%"
              className="mt-3"
            />
          </Col>
          <Col>
            <div className="showIpad">
              <h3 className="highlight mt-5">
                Kimberley Process Certification Scheme (KPCS)
              </h3>
              <p className="text-justify">
                Adoption by the UN in 2000, the Kimberley Process is a joint
                governments, industry, and civil society initiative to stem the
                flow of conflict diamonds - rough diamonds used by rebel
                movements to finance wars against legitimate governments.{' '}
              </p>
              <div className="d-flex ">
                <img src="/images/kimberley-mobile.png" width="100" />
                <div className="showMobile">
                  <div className=" d-flex  flex-column justify-content-center  ">
                    <small>
                      KP members now account for 99.8% of the global production
                      of rough diamonds
                    </small>
                    <a
                      href="https://www.kimberleyprocess.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <small>www.kimberleyprocess.com</small>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="hideIpad">
        <Row className="showMobile">
          <Col>
            <h3 className="highlight mt-5 ">
              Kimberley Process Certification Scheme (KPCS)
            </h3>
            <p className="text-justify">
              Adoption by the UN in 2000, the Kimberley Process is a joint
              governments, industry, and civil society initiative to stem the
              flow of conflict diamonds - rough diamonds used by rebel movements
              to finance wars against legitimate governments.
            </p>
          </Col>
          <Col sm="12" md="6">
            <div className="d-flex showMobile">
              <img src="/images/kimberley-mobile.png" width="100" />
              <div className="showMobile">
                <div className=" d-flex  flex-column justify-content-center  ">
                  <small>
                    KP members now account for 99.8% of the global production of
                    rough diamonds
                  </small>
                  <a
                    href="https://www.kimberleyprocess.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <small>www.kimberleyprocess.com</small>
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <Row className="mt-3 hideMobile">
        <Col sm="12" md="5">
          <img
            src="/images/traceDocumentsHero.png"
            width="100%"
            className="mt-3"
            style={{
              position: 'relative',
              top: 70,
            }}
          />
        </Col>
        <Col className="kimberley-holder">
          <h3 className="highlight mt-5">
            Kimberley Process Certification Scheme (KPCS)
          </h3>
          <p className="text-justify">
            Adoption by the UN in 2000, the Kimberley Process is a joint
            governments, industry, and civil society initiative to stem the flow
            of conflict diamonds - rough diamonds used by rebel movements to
            finance wars against legitimate governments.{' '}
          </p>

          <div className="d-flex mt-5 ">
            <img src="/images/kimberley-mobile.png" width="100" />

            <div className=" d-flex  flex-column justify-content-center  ">
              <b>
                KP members now account for 99.8% of the global production of
                rough diamonds
              </b>
              <a
                href="https://www.kimberleyprocess.com"
                target="_blank"
                rel="noreferrer"
              >
                <b>www.kimberleyprocess.com</b>
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default WorkOrdersContent;
