import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import InfoBox, { InfoBoxBase } from '../../components/InfoBox';
import ServiceFee from './ServiceFee';

const boxes: InfoBoxBase[] = [
  {
    title: 'Stock Management',
    description: 'Ethically Managed',
    color: '#FCC8FF',
  },
  {
    title: 'Sales Management',
    description: 'Ethically Managed',
    color: '#11B4F2',
  },
  {
    title: 'Service Management',
    description: 'Ethically Managed',
    color: '#0074A0',
  },
  {
    title: 'Manufacture Management',
    description: 'Ethically Managed',
    color: '#E8D6CF',
  },
  {
    title: 'Point of Sales',
    description: 'Ethically Managed',
    color: '#02FEB2',
  },
  {
    title: 'Invoice Management',
    description: 'AML Trace Protocols / Ethically Managed',
    color: '#228A6B',
  },
  {
    title: 'HR Management',
    description: 'Ethically Managed',
    color: '#FB8802',
  },
  {
    title: 'Multi Office Management',
    description: 'Ethically Managed',
    color: '#EA229A',
  },
  {
    title: 'Trace Management',
    description: 'Ethically Managed',
    color: '#FF3939',
  },
];
const mobileBoxes: InfoBoxBase[] = [
  {
    title: 'Sales Management',
    description: 'Ethically Managed',
    color: '#11B4F2',
  },
  {
    title: 'Stock Management',
    description: 'Ethically Managed',
    color: '#FCC8FF',
    largerBorder: true,
  },
  {
    title: 'Point of Sales',
    description: 'Ethically Managed',
    color: '#02FEB2',
  },
  {
    title: 'Manufacture Management',
    description: 'Ethically Managed',
    color: '#E8D6CF',
    largerBorder: true,
  },
  {
    title: 'Service Management',
    description: 'Ethically Managed',
    color: '#0074A0',
  },
  {
    title: 'Invoice Management',
    description: 'AML Trace Protocols / Ethically Managed',
    color: '#228A6B',
  },
  {
    title: 'HR Management',
    description: 'Ethically Managed',
    color: '#FB8802',
  },
  {
    title: 'Multi Office Management',
    description: 'Ethically Managed',
    color: '#EA229A',
  },
  {
    title: 'Trace Management',
    description: 'Ethically Managed',
    color: '#FF3939',
  },
];

const RetailersContent: React.FC = () => {
  return (
    <Container className="management-page">
      <Row className="my-5">
        <Col>
          <h1>Office Controls</h1>
          <p className="muted ml-5">
            Manage your small to large scale business.
          </p>
        </Col>
      </Row>
      <Row className="hideMobile">
        {boxes.map((box, key) => (
          <InfoBox key={`reatail-box-${key}`} box={box} />
        ))}
      </Row>
      <Row className="showMobile">
        <div className="row">
          {mobileBoxes.map((box, key) => (
            <InfoBox key={`reatail-box-${key}`} box={box} />
          ))}
        </div>
      </Row>
      <ServiceFee />
    </Container>
  );
};

export default RetailersContent;
