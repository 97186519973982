import React from 'react';
import { Col } from 'reactstrap';

export interface InfoBoxBase {
  title: string;
  description: string;
  subitems?: string[];
  color?: string;
  comingSoon?: boolean;
  largerBorder?: boolean;
}
interface InfoBoxProps {
  box: InfoBoxBase;
}
export default function InfoBox({ box }: InfoBoxProps): JSX.Element {
  return (
    <Col className="box-content p-1" md="6" lg="4" xs="12">
      <div
        className="box-item-content"
        style={{
          borderColor: box.color ?? '#bdbdbd',
          borderWidth: box.largerBorder ? 3 : 2,
        }}
      >
        <div className="box-item-title ">
          <h4>{box.title}</h4>
          <p dangerouslySetInnerHTML={{ __html: box.description }} />
        </div>
        {box.subitems?.length && (
          <div className="box-content-subitems">
            {box.subitems?.map((subitem, key) => (
              <span key={`subitem-${key}`}>{subitem}</span>
            ))}
          </div>
        )}
        {box.comingSoon && <div className="coming-soon">coming soon</div>}
      </div>
    </Col>
  );
}
