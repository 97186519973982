import React, { useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { Popover, Pane } from 'evergreen-ui';
import { SiteFirm } from '@wgt/types';
import RequestToJoin from '../RequestToJoin';

interface Props {
  firm: SiteFirm;
}

export default function PopoverOptions({ firm }: Props): JSX.Element {
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [modalRequestJoin, setModalRequestJoin] = useState<boolean>(false);

  const toggle = () => setPopoverOpen(!popoverOpen);

  const toggleRequestModal = () => setModalRequestJoin(!modalRequestJoin);
  return (
    <>
      <Popover
        statelessProps={{
          className: 'popover-request-join',
        }}
        onOpenComplete={toggle}
        onCloseComplete={toggle}
        content={
          <Pane width={205} height={105} display="flex" flexDirection="column">
            <div className="p-3">
              <div>
                <button
                  className="px-0 mb-3 btn-request-join"
                  onClick={toggleRequestModal}
                >
                  Request to join
                </button>
              </div>
              <p className="text-muted mb-0">
                <small>Provide your contact information</small>
              </p>
            </div>
          </Pane>
        }
      >
        <button id={`popover-${firm.id}`} className="btn-icon">
          {popoverOpen ? <FiChevronUp /> : <FiChevronDown />}
        </button>
      </Popover>
      {modalRequestJoin && (
        <RequestToJoin toggleRequestModal={toggleRequestModal} firm={firm} />
      )}
    </>
  );
}
