import { gql } from '@apollo/client';

export const CREATE_NEWSLETTER = gql`
  mutation createNewsletterSubscription($email: String!, $ip: String!) {
    createNewsletterSubscription(email: $email, ip: $ip) {
      id
    }
  }
`;
export const UPDATE_NEWSLETTER = gql`
  mutation updateNewsletterSubscription(
    $email: String!
    $newsletterSubscription: UpdateNewsletterSubscriptionInput!
  ) {
    updateNewsletterSubscription(
      email: $email
      newsletterSubscription: $newsletterSubscription
    ) {
      id
    }
  }
`;

export const UNSUBSCRIBE_NEWSLETTER = gql`
  mutation unsubscribe($email: String!) {
    unsubscribeNewsletterSubscription(email: $email) {
      message
    }
  }
`;
