/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Button, Col, Form, Row } from 'reactstrap';
import { useForm, FormProvider } from 'react-hook-form';
import SimpleInput from '../SimpleInput';
import { emailRegex } from '../../utils/constants';

interface ForgotPasswordFormVariables {
  email: string;
  password: string;
  confirmPassword: string;
  textCode?: string;
}

interface ForgotPasswordFormProps {
  onSuccess(props?: any): void;
}
const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  onSuccess,
}) => {
  const methods = useForm<ForgotPasswordFormVariables>();

  const onSubmit = (variables: ForgotPasswordFormVariables) =>
    onSuccess(variables);

  return (
    <>
      <Row>
        <Col>
          <h3>Login</h3>
        </Col>
      </Row>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <SimpleInput
                field="email"
                label="Email"
                extraRegisterProps={{ pattern: emailRegex }}
                required
              />
            </Col>
          </Row>

          <Row className="mt-5">
            <Col>
              <SimpleInput
                field="password"
                placeholder="Enter New Password"
                type="password"
                required
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <SimpleInput
                field="confirmPassword"
                placeholder="Confirm Password"
                extraRegisterProps={{
                  required: true,
                  validate: (value: string) => {
                    return value === methods.getValues('password');
                  },
                }}
                type="password"
                required
              />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-end mr-3">
              <Button color="secondary" size="sm">
                Login
              </Button>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <SimpleInput
                field="textCode"
                label="Text Code Confirmation"
                required
              />
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </>
  );
};

export default ForgotPasswordForm;
