/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef, useEffect, useMemo } from 'react';
import Select from 'react-select';
import {
  DocumentNode,
  LazyQueryHookOptions,
  useLazyQuery,
} from '@apollo/client';
import { OperationDefinitionNode } from 'graphql';
import classnames from 'classnames';
import useDebounce from '../../hooks/useDebounce';
import useFakeLazyQuery from '../../hooks/useFakeLazyQuery';
import './styles.scss';

interface SelectSearchProps {
  gql: DocumentNode;
  name: string;
  placeholder?: string;
  debounce?: number;
  getOptionLabel?(value: any): string;
  getOptionValue?(value: any): string;
  queryOptions?: LazyQueryHookOptions;
  parseVariables?(variables: any): any;
  parseResults?(items: any): any;
  onChange?(value: any): any;
  defaultInputValue?: any;
  invalid?: boolean;
  triggerQuery?: boolean | string | number;
  fakeData?: any;
  isDisabled?: boolean;
  className?: string;
}

export default forwardRef(function SelectSearch(
  {
    gql,
    name,
    getOptionLabel,
    getOptionValue,
    queryOptions,
    placeholder = 'type to search',
    parseVariables = (variables) => variables,
    parseResults = (results) => results,
    onChange,
    defaultInputValue,
    invalid,
    triggerQuery,
    fakeData,
    isDisabled = false,
    className,
  }: SelectSearchProps,
  ref: any,
) {
  const debounce = useDebounce();
  const [refetch, { data, loading, called }] = fakeData
    ? useFakeLazyQuery(gql, { ...queryOptions, defaultValue: fakeData })
    : useLazyQuery(gql, queryOptions);
  const operation = gql.definitions.find(
    (x) => x.kind === 'OperationDefinition',
  ) as OperationDefinitionNode;
  const nodes = {
    fetch: operation?.name?.value ?? '',
  };

  const { data: items } = useMemo(() => data?.[nodes.fetch] ?? { data: [] }, [
    data?.[nodes.fetch],
  ]);
  useEffect(() => {
    ref?.current?.select?.clearValue();
    refetch({
      variables: parseVariables({
        filter: {},
      }),
    });
  }, [triggerQuery]);

  const onInputChange = (value: string) => {
    if (!value.length) {
      return;
    }
    debounce(() =>
      refetch({
        variables: parseVariables({ filter: { [name]: `%${value}%` } }),
      }),
    );
  };

  return (
    <Select
      className={classnames({
        invalid,
      })}
      classNamePrefix={classnames(className)}
      options={parseResults(fakeData ? data : items)}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      name={name}
      onChange={(e) => onChange?.(e)}
      ref={ref}
      isSearchable
      placeholder={placeholder}
      onInputChange={onInputChange}
      isClearable
      isLoading={loading}
      defaultInputValue={defaultInputValue}
      noOptionsMessage={() => (called ? 'No option' : 'Type to search')}
      isDisabled={isDisabled}
    />
  );
});
