import React, { useEffect, useState } from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { UserExistByEmailInput } from '@wgt/types';
import { useLazyQuery } from '@apollo/client';
import { emailRegex } from '../../utils/constants';
import AccountForm from '../../components/AccountForm';
import { CHECK_USER_EXIST_BY_EMAIL } from '../../services/gql/account';
import useRegisterContext, { RegisterProvider } from '../../hooks/useRegister';
import ContactBox from './ContactBox';

const RegisterComponent: React.FC = () => {
  const {
    firmSaved,
    setFirmSaved,
    requestSent,
    setRequestSent,
  } = useRegisterContext();

  const [showExtraForm, setShowExtraForm] = useState<
    UserExistByEmailInput | undefined
  >();
  const methods = useForm<UserExistByEmailInput>();
  const { handleSubmit, register, errors, getValues } = methods;

  const [fetch, { data: dataUser, loading: loadingUser }] = useLazyQuery<{
    _userExists: { exists: boolean };
  }>(CHECK_USER_EXIST_BY_EMAIL, {
    onCompleted: (responseData) => {
      if (responseData._userExists?.exists === false) {
        setShowExtraForm(getValues(['email', 'phone', 'last_name']));
      } else {
        setShowExtraForm(undefined);
      }
      setFirmSaved();
      setRequestSent();
    },
  });

  const onSubmit = (variables: UserExistByEmailInput) => {
    fetch({
      variables: {
        filter: variables,
      },
    });
  };

  useEffect(() => {
    if (firmSaved || requestSent) {
      methods.reset();
      setShowExtraForm(undefined);
    }
  }, [firmSaved, requestSent]);

  return (
    <div className="mt-5 register-page">
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Row>
            <Col
              xs={{ size: 12, order: 1 }}
              md={{ size: 6, order: 0 }}
              lg="6"
              xl="5"
            >
              <div>
                <h1 className="mb-1">Company Registration</h1>
                <p className="text-secondary ml-5">
                  Please provide your business description.
                </p>
              </div>
              <div className="d-none d-md-block d-lg-none mt-4">
                <ContactBox />
              </div>
            </Col>
            <Col
              xs={{ size: 12, order: 0 }}
              md={{ size: 6, order: 1 }}
              lg="6"
              xl="7"
              className="d-block d-md-none d-lg-block"
            >
              <ContactBox />
            </Col>

            <Col
              className="check-account-form"
              xs={{ size: 12, order: 2 }}
              md={{ size: 6 }}
              lg={{ size: 6, offset: 6 }}
              xl={{ size: 7, offset: 5 }}
            >
              <div className="w-100 mr-5 mr-lg-2 mr-xl-5 mt-4 mt-md-0 ">
                <FormGroup>
                  <Label>Enter your email</Label>
                  <Input
                    name="email"
                    innerRef={register({
                      pattern: emailRegex,
                      required: true,
                    })}
                    invalid={!!errors?.email}
                    required
                  />
                </FormGroup>
                <FormGroup className="mb-0">
                  <Label>Enter your phone</Label>
                  <Input
                    name="phone"
                    innerRef={register({ required: true })}
                    invalid={!!errors?.phone}
                  />
                </FormGroup>
              </div>
              <div className="d-flex align-items-end request-button-holder justify-content-end mt-3">
                <button
                  className="btn-wgt dark-blue d-flex align-items-stretch"
                  type="submit"
                  disabled={loadingUser ?? false}
                >
                  {loadingUser && (
                    <div className="d-flex align-items-center mr-3">
                      <span className="spinner-border spinner-border-sm text-light" />
                    </div>
                  )}
                  OK
                </button>
              </div>
            </Col>
          </Row>
          {!showExtraForm && (
            <Row>
              <Col>
                <div className="d-flex algin-items-center justify-content-end">
                  <p className="my-5">Happy to be working with you.</p>
                </div>
              </Col>
            </Row>
          )}
        </Form>
      </FormProvider>

      {!!showExtraForm && !loadingUser && <AccountForm user={showExtraForm} />}

      {dataUser?._userExists?.exists && (
        <div className="d-flex justify-content-center">
          <div className="alert-congradulations w-75">
            <p className="xlarge">
              This contact information is already connected to a firm. The next
              registration must be done from the employer. Please contact your
              employer for a request to join.
            </p>
          </div>
        </div>
      )}

      {!!firmSaved && (
        <div className="d-flex justify-content-center">
          <div className="alert-congradulations w-75">
            <p className="xlarge mb-3">Congratulations!</p>
            <p className="xlarge mb-2">
              An email has been sent to {firmSaved.name}.
            </p>
            <p className="mb-2">{firmSaved.email}</p>
            <p>
              This is for early registration and we are going to provide you
              access soon.
            </p>
          </div>
        </div>
      )}

      {!!requestSent && (
        <div className="d-flex justify-content-center">
          <div className="alert-congradulations w-75">
            <p className="xlarge mb-3">Congratulations!</p>
            <p className="xlarge mb-3">
              An email has been sent to {requestSent.first_name}{' '}
              {requestSent.last_name}.
            </p>
            <p className="mb-3">
              Please tell {requestSent.first_name} {requestSent.last_name} that
              an email is waiting in their inbox to provide you with the
              permission to create a company account.
            </p>
            <Row>
              <Col xs="12" lg="7">
                <p className="mb-3">
                  {requestSent.first_name} {requestSent.last_name}
                </p>
                <p>{requestSent.email}</p>
              </Col>
              <Col xs="12" lg="5">
                <p className="mb-3">
                  {requestSent.business_phone}{' '}
                  {requestSent.business_phone_extension}
                </p>
                <p>{requestSent.business_mobile}</p>
              </Col>
            </Row>
            <p className="mt-3">Message</p>
            <p>{requestSent.message}</p>
          </div>
        </div>
      )}
    </div>
  );
};

const RegisterForm: React.FC = () => {
  return (
    <RegisterProvider>
      <RegisterComponent />
    </RegisterProvider>
  );
};

export default RegisterForm;
