import React, { useState } from 'react';
import { UserExistByEmailInput, FirmSignUpInput } from '@wgt/types';
import { useForm, FormProvider } from 'react-hook-form';
import { Form } from 'reactstrap';
import { useMutation, ApolloError } from '@apollo/client';
import PersonalForm from './PersonalForm';
import PermissionForm from './PermissionForm';
import CreateProfile from './CreateProfile';
import { SIGN_UP } from '../../services/gql/account';
import Firms from './Firms';
import useRegisterContext from '../../hooks/useRegister';
import useToast from '../../hooks/useToast';
import FirmTypes from './FirmTypes';

interface Props {
  user: UserExistByEmailInput;
}

export default function AccountForm({ user }: Props): JSX.Element {
  const [section, setSection] = useState<number>(0);
  const [formError, setFormError] = useState<string>('');

  const { toast } = useToast();

  const { setFirmSaved } = useRegisterContext();

  const methods = useForm<FirmSignUpInput>({
    shouldUnregister: false,
    defaultValues: {
      office: {
        category_ids: [],
        types: [],
      },
      user: {
        job_title_ids: [],
      },
    },
  });

  const { handleSubmit, getValues, setError } = methods;

  const onSubmit = async (data: FirmSignUpInput) => {
    if (!data.user.job_title_ids?.length) {
      setError('user.job_title_ids', { type: 'required' });
      return;
    }

    const jobTitleIds = data.user?.job_title_ids?.filter(
      (item: string) => item !== '0',
    );

    const variables = {
      ...data,
      own_company: undefined,
      user: {
        ...data.user,
        email: user.email,
        phone: user.phone,
        toll_free_business_number: data.firm?.address?.alt_phone,
        job_title_ids: jobTitleIds,
      },
      firm: {
        ...data.firm,
        website: data.firm?.website?.length ? data.firm.website : null,
        address: {
          ...data.firm.address,
          country: undefined,
          country_id: data.firm.address?.country?.id,
        },
      },
      office: {
        ...data.office,
        address: data.firm.address?.address,
        address_2: data.firm.address?.address_2,
        phone: user.phone,
        postal_code: data.firm.address?.postal_code,
        city: data.firm.address?.city,
        state: data.firm.address?.state,
        country_id: data.firm.address?.country?.id,
      },
    };

    create({ variables });
  };

  const [create, { loading: signUpLoading }] = useMutation<FirmSignUpInput>(
    SIGN_UP,
    {
      onCompleted: () => [
        setFirmSaved({
          name: `${getValues('user.first_name')} ${getValues(
            'user.last_name',
          )}`,
          email: user.email,
        }),
        setSection(0),
        methods.reset(),
      ],
      onError: (error: ApolloError) => {
        const validations = error?.graphQLErrors?.[0]?.extensions?.validation;
        Object.keys(validations).forEach((key) => {
          setError(key, { message: validations[key], type: 'validate' });
        });
        setFormError(Object.values(validations).toString());
        toast('error', 'Please correct the following errors and try again');
      },
    },
  );

  return (
    <section>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <FirmTypes setSection={setSection} section={section} />
          {section > 0 && <PersonalForm setSection={setSection} user={user} />}
          {section > 1 && <PermissionForm setSection={setSection} />}
          {section > 2 && (
            <CreateProfile
              signUpLoading={signUpLoading}
              formError={formError}
            />
          )}
        </Form>
      </FormProvider>

      <Firms />
    </section>
  );
}
