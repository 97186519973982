/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import CreateAccountButton from '../../components/CreateAccountButton';
import IndexComponents from '../../components/IndexComponents';
import IndexSearchService from '../../components/IndexSearchService';
import { InvoicesProvider } from '../../hooks/userInvoicesContext';

const Index: React.FC = () => {
  const [heightBlock, setHeightBlock] = useState<string>('');
  const [widthBlock, setWidthBlock] = useState<string>('');
  const [marginButtonTop, setMarginButtonTop] = useState<string>('');
  const [widthButton, setWidthButton] = useState<string>('');

  const ResizeCalc = (values: any) => {
    const differenceScreen = values.maxScreen - values.minScreen;
    const differenceElement = values.maxElement - values.minElement;
    const differenceWidth = values.maxScreen - values.currentWidth;
    const difference = differenceScreen - differenceWidth;
    const ratio = difference * (differenceElement / differenceScreen);
    return values.minElement + ratio;
  };

  useEffect(() => {
    function resizeHeightAccountBlock(width: any) {
      if (width <= 767) {
        let dimesionsHeightBlock = {};
        let dimesionsWidthBlock = {};
        let dimesionsButtonTop = {};

        if (width <= 575) {
          dimesionsHeightBlock = {
            currentWidth: width,
            maxScreen: 575,
            minScreen: 320,
            maxElement: 458,
            minElement: 261,
          };

          dimesionsButtonTop = {
            currentWidth: width,
            maxScreen: 575,
            minScreen: 320,
            maxElement: 60,
            minElement: 25,
          };

          dimesionsWidthBlock = {
            currentWidth: width,
            maxScreen: 575,
            minScreen: 320,
            maxElement: 495,
            minElement: 279,
          };
          setWidthButton('');
        } else {
          dimesionsHeightBlock = {
            currentWidth: width,
            maxScreen: 767,
            minScreen: 576,
            maxElement: 335,
            minElement: 285,
          };

          dimesionsButtonTop = {
            currentWidth: width,
            maxScreen: 767,
            minScreen: 576,
            maxElement: 46,
            minElement: 61,
          };

          dimesionsWidthBlock = {
            currentWidth: width,
            maxScreen: 767,
            minScreen: 576,
            maxElement: 359,
            minElement: 270,
          };

          const resultCalcWidthButton = ResizeCalc({
            currentWidth: width,
            maxScreen: 767,
            minScreen: 576,
            maxElement: 322,
            minElement: 232,
          });
          setWidthButton(`${resultCalcWidthButton}px`);
        }

        const resultCalcHeightBlock = ResizeCalc(dimesionsHeightBlock);
        const resultMarginButtonTop = ResizeCalc(dimesionsButtonTop);
        const resultCalcWidthBlock = ResizeCalc(dimesionsWidthBlock);

        setHeightBlock(`${resultCalcHeightBlock}px`);
        setMarginButtonTop(`${resultMarginButtonTop}px`);
        setWidthBlock(`${resultCalcWidthBlock}px`);
      } else {
        setHeightBlock('');
        setMarginButtonTop('');
        setWidthBlock('');
      }
    }

    const handlerResize = () => {
      const width = window.document.documentElement.clientWidth;
      resizeHeightAccountBlock(width);
    };

    handlerResize();
    window.addEventListener('resize', handlerResize);

    return () => window.removeEventListener('resize', handlerResize);
  }, []);

  return (
    <InvoicesProvider>
      <div className="container-md container-lg container-xl container-xxl">
        <Row className="my-5">
          <Col sm="5">
            <h1>
              Your Stock
              <br /> is secure.
            </h1>
            <p className="muted hideMobile hideTablet">
              Connect your beauty around the world. <br />
              Trace your stock!
            </p>
            <p className="muted showMobile showTablet">
              Connect your beauty around the world. <br />
              Trace your stock!
            </p>
          </Col>
          <Col>
            <img src="/images/hero.png" className="w-100" />
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="4">
            <div>
              <h4>Retailers</h4>
              <p className="muted">
                Tracing stock increases ethical sourcing and security.
              </p>
            </div>
          </Col>
          <Col xs="12" md="4">
            <div>
              <h4>Suppliers</h4>
              <p className="muted">
                Maintain fast AML protocols and stock records.
              </p>
            </div>
          </Col>
          <Col xs="12" md="4">
            <div>
              <h4>Industry</h4>
              <p className="muted">
                Trading in a trace network provides value to your supply.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="col-12 col-md-4 col-xxl-3">
            <IndexSearchService />
          </div>
        </Row>
        <IndexComponents />
        <div className="create-account-mobile">
          <Row className="mt-5 create-account-mobile-content">
            <Col
              xs="12"
              sm={{ size: 6, offset: 6 }}
              md={{ size: 4, offset: 8 }}
              className="create-account-button-holder"
              style={{
                ...(heightBlock !== '' && { height: heightBlock }),
                ...(widthBlock !== '' && { backgroundSize: widthBlock }),
                ...(marginButtonTop !== '' && {
                  paddingTop: marginButtonTop,
                }),
              }}
            >
              <div
                style={{
                  ...(widthButton !== '' && { width: widthButton }),
                }}
              >
                <CreateAccountButton />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </InvoicesProvider>
  );
};

export default Index;
