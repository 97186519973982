import React, { useEffect, useRef } from 'react';
import { Container } from 'reactstrap';
import { SiteFirm } from '@wgt/types';
import FirmItem from './FirmItem';
import useRegisterContext from '../../../hooks/useRegister';

export default function Firms(): JSX.Element {
  const titleRef = useRef<HTMLHeadingElement>(null);
  const { showListFirm, listFirm } = useRegisterContext();

  useEffect(() => {
    if (listFirm?.length) {
      setTimeout(
        () =>
          titleRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          }),
        300,
      );
    }
  }, [listFirm]);

  return (
    <>
      {!!listFirm?.length && showListFirm && (
        <div className="mt-4">
          <h4 className="highlight mb-4 pl-2 border-bottom pb-2" ref={titleRef}>
            Search Results of Available Accounts
          </h4>
          <Container fluid>
            {listFirm.map((firm: SiteFirm, index: number) => (
              <FirmItem firm={firm} key={`firm-${index}`} />
            ))}
          </Container>
        </div>
      )}
    </>
  );
}
