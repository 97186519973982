/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Col, FormGroup, Input, InputProps, Label } from 'reactstrap';
import classnames from 'classnames';
import { getProp } from '@wgt/shared';

interface SimpleInputProps extends InputProps {
  field: string;
  prefix?: string;
  index?: number;
  label?: string;
  required?: boolean;
  placeholder?: string;
  column?: number;
  extraRegisterProps?: Record<string, unknown>;
  className?: string;
}

const SimpleInput: React.FC<SimpleInputProps> = ({
  field,
  label,
  required,
  placeholder,
  prefix,
  column = 12,
  extraRegisterProps,
  className,
  ...props
}) => {
  const { register, errors } = useFormContext();

  const fieldToManage = prefix ? `${prefix}[${field}]` : field;

  const hasError = prefix
    ? !!errors && !!errors[prefix] && !!errors[prefix][field]
    : !!errors && !!errors[field];

  return (
    <FormGroup row={column !== 12} className={className}>
      {label && (
        <Label
          for={`${fieldToManage}-field`}
          md={column !== 12 ? 12 - column : 12}
          xs="12"
        >
          {label}
        </Label>
      )}
      <Col md={column} xs="12">
        <Input
          innerRef={register({
            required,
            ...extraRegisterProps,
          })}
          name={fieldToManage}
          id={`${fieldToManage}-field`}
          placeholder={placeholder}
          invalid={Boolean(hasError)}
          defaultValue=""
          className={classnames('form-control', {
            'is-invalid': getProp(errors, fieldToManage) || hasError,
          })}
          {...props}
        />
      </Col>
    </FormGroup>
  );
};

export default SimpleInput;
