import React from 'react';
import { useLocation } from 'react-router-dom';
import ForgotPasswordForm from '../ForgotPasswordForm';
import LoginForm from '../LoginForm';

import useAuthenticationCookies from '@wgt/authentication/useAuthenticationCookies';
import { CRM_URL } from '../../utils/constants';

export interface AuthUser {
  name: string;
  access_token: string;
  token_refresh: string;
}

interface LoginModalContentProps {
  toggle(): void;
}
const LoginModalContent: React.FC<LoginModalContentProps> = ({ toggle }) => {
  const location = useLocation();
  const [, setCookie] = useAuthenticationCookies();
  const handleSignIn = async (login: AuthUser) => {
    await setCookie(login);
    window.open(CRM_URL, '_blank');
    toggle();
  };
  return (
    <>
      <div className="close-login-modal" onClick={toggle}>
        Close menu <img src="/images/closeModalBtn.png" />
      </div>

      <div className="flex-1 d-flex flex-column ">
        <div className="flex-1">
          <hr className="row" />
          {location.hash === '#login' && <LoginForm onSuccess={handleSignIn} />}
          {location.hash === '#forgotPassword' && (
            <ForgotPasswordForm onSuccess={toggle} />
          )}
        </div>
        <b>Thank you for being a member of World Gem Trade</b>
      </div>
    </>
  );
};

export default LoginModalContent;
