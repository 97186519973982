import React from 'react';
import './styles.scss';
import classnames from 'classnames';

interface Props {
  rounded?: boolean;
  small?: boolean;
  className?: string;
}

const Loader: React.FC<Props> = ({ small, rounded, className }) => {
  if (rounded) {
    return <div className={classnames('round-loader', className, { small })} />;
  }
  return (
    <div className={classnames('bouncing-loader', { small })}>
      <div />
      <div />
      <div />
    </div>
  );
};

export default Loader;
