import React from 'react';
import Footer from '../../components/Footer';
import Menu from '../../components/Menu';
import RetailersContent from './RetailersContent';
import './styles.scss';

const Retailers: React.FC = () => {
  return (
    <div className="retailers-page">
      <Menu />
      <RetailersContent />
      <Footer />
    </div>
  );
};

export default Retailers;
