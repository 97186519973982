import React from 'react';
import { Col } from 'reactstrap';
import { IndexComponent } from '.';
import { FaChevronCircleRight } from 'react-icons/fa';
import clasnames from 'classnames';

interface IndexComponentItemProps {
  component: IndexComponent;
  setModalContent: React.Dispatch<React.SetStateAction<string | null>>;
  className?: string;
  isActive?: boolean;
}

const IndexComponentItem: React.FC<IndexComponentItemProps> = ({
  component,
  className,
  isActive,
}) => {
  return (
    <Col
      className={clasnames('index-component-item', className, {
        isActive,
      })}
      md="4"
      xs="12"
    >
      <div className="content-holder">
        <h4>
          {component.title} <br />
          <small dangerouslySetInnerHTML={{ __html: component.description }} />
        </h4>
        <div className="d-flex align-items-center justify-content-between">
          <div
            style={{ backgroundColor: component.color }}
            className="color-box"
          />
          <FaChevronCircleRight className="ml-5" />
        </div>
      </div>
    </Col>
  );
};

export default IndexComponentItem;
