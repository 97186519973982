import React from 'react';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { UncontrolledTooltip } from 'reactstrap';
import classnames from 'classnames';

const ToolTipBuyingPublic: React.FC<{ text?: string; className?: string }> = ({
  text,
  className,
}) => {
  return (
    <>
      <FaRegQuestionCircle
        id="tooltipBuyingPublic"
        className={classnames(className)}
      />
      <UncontrolledTooltip placement="bottom" target="tooltipBuyingPublic">
        {text ?? (
          <>
            Client does not <br />
            have a jewelry business
          </>
        )}
      </UncontrolledTooltip>
    </>
  );
};

export default ToolTipBuyingPublic;
