/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Row, Col, Label } from 'reactstrap';
import { useFormContext } from 'react-hook-form';

interface Props {
  items: any;
  field: string;
  onClick?: (field: string) => void;
  popoverId?: string;
}

export default function ItemsSelect({
  items,
  field,
  onClick,
  popoverId,
}: Props): JSX.Element {
  const { getValues, setValue } = useFormContext();

  const handleCheck = (item: any) => {
    const fieldValues = getValues(field);
    const index = fieldValues.indexOf(item.id);
    let values = [];
    if (index !== -1) {
      fieldValues.splice(index, 1);
      values = fieldValues;
    } else {
      values = [...fieldValues, item.id];
    }
    setValue(field, values);
    onClick?.(field);
  };

  return (
    <Row>
      {items.map((item: any) => (
        <Col
          xs="12"
          lg="4"
          key={`${popoverId}-${field}-${item.id}`}
          className="d-flex justify-content-between mb-1"
        >
          <Label
            className="d-flex align-items-center label-office-category"
            htmlFor={`${popoverId}-${field}-${item.id}`}
          >
            {item.name}
          </Label>
          <div className="d-flex align-items-center">
            <input
              type="checkbox"
              id={`${popoverId}-${field}-${item.id}`}
              onChange={() =>
                handleCheck({
                  id: item.id,
                })
              }
              defaultChecked={getValues(field)?.includes(item.id)}
            />
          </div>
        </Col>
      ))}
    </Row>
  );
}
