import React from 'react';
import { Button, ButtonProps } from 'reactstrap';
import Loader from '../Loader';

interface CustomButtonProps extends ButtonProps {
  isLoading?: boolean;
  text: string;
}
const CustomButton: React.FC<CustomButtonProps> = ({
  isLoading,
  text,
  ...props
}) => {
  return (
    <Button
      color="secondary"
      size="sm"
      disabled={isLoading ?? false}
      className="d-flex align-items-center"
      {...props}
    >
      {isLoading && <Loader rounded small className="mr-1" />} {text}
    </Button>
  );
};

export default CustomButton;
