/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Row, Col } from 'reactstrap';
import { UserExistByEmailInput } from '@wgt/types';
import { useFormContext } from 'react-hook-form';
import SimpleInput from '../SimpleInput';
import useRegisterContext from '../../hooks/useRegister';

interface Props {
  setSection: (section: number) => void;
  user: UserExistByEmailInput;
}

export default function PersonalForm({ setSection, user }: Props): JSX.Element {
  const { trigger, getValues } = useFormContext();
  const { setUserData } = useRegisterContext();

  const nextStep = async () => {
    const result = await trigger(['user.first_name', 'user.last_name']);
    if (result) {
      setUserData({
        first_name: getValues('user.first_name'),
        last_name: getValues('user.last_name'),
        email: user.email,
      });
      setSection(2);
    }
  };

  return (
    <>
      <section className="mt-5 register-personal-form">
        <p className="mb-3 large">Enter your contact information</p>
        <Row className="no-gutters">
          <Col xs="12" lg="3">
            <SimpleInput
              field="user.first_name"
              label="First Name"
              required
              column={9}
            />
          </Col>
          <Col xs="12" lg="3">
            <SimpleInput
              field="user.last_name"
              label="Last Name"
              required
              column={9}
            />
          </Col>
          <Col xs="12" lg="3">
            <SimpleInput
              field="phone"
              label="Phone"
              required
              column={9}
              disabled
              defaultValue={user.phone}
              prefix="user"
            />
          </Col>
          <Col xs="12" lg="3">
            <SimpleInput
              field="email"
              label="Email"
              required
              column={9}
              disabled
              defaultValue={user.email}
              prefix="user"
            />
          </Col>
        </Row>
        <div className="text-right">
          <button
            className="btn-wgt dark-blue"
            type="button"
            onClick={nextStep}
          >
            OK
          </button>
        </div>
      </section>
    </>
  );
}
