import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import InfoBox, { InfoBoxBase } from '../../components/InfoBox';

const boxes: InfoBoxBase[] = [
  {
    title: 'Color Diamonds',
    description: 'Ethically Traced',
    color: '#FCC8FF',
  },
  {
    title: 'Diamonds',
    description: 'Ethically Traced',
    color: '#11B4F2',
  },
  {
    title: 'Rough Diamonds',
    description: 'Ethically Traced',
    color: '#0074A0',
  },
  {
    title: 'Pearls',
    description: 'Ethically Traced',

    color: '#E8D6CF',
  },
  {
    title: 'Gemstones',
    description: 'Ethically Traced',
    color: '#02FEB2',
  },
  {
    title: 'Rough Gemstones',
    description: 'Ethically Traced',
    color: '#228A6B',
  },
  {
    title: 'Metals',
    description: 'Ethically Traced',
    color: '#FB8802',
  },
  {
    title: 'Jewelry & Watches',
    description: 'Ethically Traced',
    color: '#EA229A',
    comingSoon: true,
  },
  {
    title: 'Laboratory Reports',
    description: 'Ethically Traced',
    color: '#FF3939',
  },
];
const mobileBoxes: InfoBoxBase[] = [
  {
    title: 'Diamonds',
    description: 'Ethically Traced',
    color: '#11B4F2',
  },
  {
    title: 'Color Diamonds',
    description: 'Ethically Traced',
    color: '#FCC8FF',
    largerBorder: true,
  },
  {
    title: 'Gemstones',
    description: 'Ethically Traced',
    color: '#02FEB2',
  },
  {
    title: 'Pearls',
    description: 'Ethically Traced',
    color: '#E8D6CF',
    largerBorder: true,
  },
  {
    title: 'Rough Diamonds',
    description: 'Ethically Traced',
    color: '#0074A0',
  },
  {
    title: 'Rough Gemstones',
    description: 'Ethically Traced',
    color: '#228A6B',
  },
  {
    title: 'Metals',
    description: 'Ethically Traced',
    color: '#FB8802',
  },
  {
    title: 'Jewelry & Watches',
    description: 'Ethically Traced',
    color: '#EA229A',
    comingSoon: true,
  },
  {
    title: 'Laboratory Reports',
    description: 'Ethically Traced',
    color: '#FF3939',
  },
];

const RetailersContent: React.FC = () => {
  return (
    <Container>
      <Row className="my-5">
        <Col>
          <h1>Ethical Sources</h1>
          <p className="muted ml-5">
            Buy, sell, and trace stock around the world.
          </p>
        </Col>
      </Row>
      <Row className="hideMobile">
        {boxes.map((box, key) => (
          <InfoBox key={`reatail-box-${key}`} box={box} />
        ))}
      </Row>
      <Row className="showMobile">
        <div className="row">
          {mobileBoxes.map((box, key) => (
            <InfoBox key={`reatail-box-${key}`} box={box} />
          ))}
        </div>
      </Row>
      <Row className="mb-5 invoice-section">
        <div className="col-12 col-sm-10 col-md-6 col-lg-5 pr-sx-3 pr-xxl-5 pl-0 pl-lg-3">
          <img
            src="/images/retailersHero.png"
            width="100%"
            className="retailers-img-hero"
          />
        </div>
        <div className="col-12 col-md-6 col-lg-6 offset-lg-1 document-content">
          <h2 className="highlight mb-3">Invoicing</h2>
          <b>Point of Sales Invoicing</b>
          <p className="ml-5 mb-4 mb-lg-5">
            Manage policy types with consignments and sold.
          </p>
          <b>AML Statements</b>
          <p className="ml-5">
            By law, a company doing $50,000 or more in transaction per year must
            maintain their AML statements.
          </p>
          <p className="ml-5 ">
            Everything to know on transactions with payment schedule management.
          </p>
        </div>
      </Row>
    </Container>
  );
};

export default RetailersContent;
