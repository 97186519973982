import React from 'react';
import { SiteFirm } from '@wgt/types';
import PopoverOptions from './PopoverOptions';

interface Props {
  firm: SiteFirm;
}

export default function ListFirm({ firm }: Props): JSX.Element {
  return (
    <>
      <div className="row border rounded py-2 my-3">
        <div className="col-auto pl-2 pr-0">
          <img
            src={
              firm.extra?.logo_url
                ? firm.extra?.logo_url
                : `/images/logo-dummy.png`
            }
            className="img-thumbnail-small"
          />
        </div>

        <div className="col-5 col-md-3 pr-0 d-flex align-items-center">
          <h5 className="highlight">{firm.name}</h5>
        </div>
        <div className="d-none col-1 d-md-block col-md-3 ">
          <div className="d-flex align-items-center">
            <div className="text-extended">
              <p>
                <strong>{firm.address?.phone}</strong>
              </p>
              <p className="small">{firm.email}</p>
            </div>
          </div>
        </div>
        <div className="d-none col-1 d-md-block col-md-3">
          <div className="px-0 d-flex align-items-center">
            <div>
              <p className="small">{firm.address?.address}</p>
              <p className="small">
                {firm.address?.city}
                {!!firm.address?.city && ','} {firm.address?.state}
              </p>
              <p className="small">
                {firm.address?.country?.name}
                {!!firm.address?.country?.name && ','}{' '}
                {firm.address?.postal_code}
              </p>
            </div>
          </div>
        </div>
        <div className="col-1 pl-0 ml-auto d-flex justify-content-end align-items-center">
          <div>
            <PopoverOptions firm={firm} />{' '}
          </div>
        </div>
      </div>
    </>
  );
}
