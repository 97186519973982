import React, { useMemo, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Col, Container, Form, Row } from 'reactstrap';
import publicIp from 'public-ip';
import { useMutation } from '@apollo/client';
import useToast from '../../hooks/useToast';
import Newsletter from '../Newsletter';
import NewsletterExtraFieldsForm from '../NewsletterExtraFieldsForm';
import { UPDATE_NEWSLETTER } from '../../services/gql/newsletter';
import userNewsletterContext, {
  NewsletterProvider,
  NewsletterVariables,
} from '../../hooks/useNewsletter';
import './styles.scss';

const FooterComponent: React.FC = () => {
  const methods = useForm<NewsletterVariables>({
    shouldUnregister: false,
    defaultValues: {
      category_ids: [],
      office_types: [],
    },
  });
  const {
    setExtraOpen,
    isExtraOpen,
    createNewsletter,
    creating,
    isLoading,
  } = userNewsletterContext();
  const { toast } = useToast();

  const { errors, trigger, getValues, handleSubmit, reset } = methods;

  const formRef = useRef<HTMLFormElement | null>(null);

  const [updateNewsletter, { loading: updateLoading }] = useMutation<
    NewsletterVariables
  >(UPDATE_NEWSLETTER, {
    onCompleted: () => {
      reset({
        category_ids: [],
        office_types: [],
      });
      toast('success', 'Thank you for registering');
      setExtraOpen(false);
    },
  });

  const onSubmit = handleSubmit((variables) => {
    const newsletterSubscription = {
      ...variables,
      ...(variables.country && { country_id: variables.country?.id }),
      country: undefined,
      email: undefined,
    };

    updateNewsletter({
      variables: {
        email: variables.email,
        newsletterSubscription,
      },
    });
  });

  const onClick = async (e?: React.MouseEvent<HTMLButtonElement>) => {
    let ip;
    try {
      ip = await publicIp.v4();
    } catch (error) {
      ip = '';
    }
    const result = await trigger('email');
    if (result && !isExtraExpanded) {
      createNewsletter({
        variables: {
          email: getValues('email') ?? '',
          ip,
        },
      });
    } else {
      const checkAll = await trigger();
      if (checkAll) {
        onSubmit(e);
      }
    }
  };

  const isExtraExpanded = useMemo(
    () => isExtraOpen && !errors?.email && !creating,
    [isExtraOpen, errors, creating],
  );

  return (
    <FormProvider {...methods}>
      <Form onSubmit={onSubmit} innerRef={formRef} className="container-fluid">
        {isExtraExpanded && (
          <NewsletterExtraFieldsForm updateLoading={updateLoading} />
        )}

        <Row className="d-flex align-items-center justify-content-center flex-column flex-1 py-3 footer">
          <Col className="newsletter-holder">
            <Container>
              <Row>
                <Col xs="12" lg="6" md="8" className="py-4">
                  <Newsletter
                    onClick={onClick}
                    active={isExtraExpanded}
                    isLoading={isLoading || updateLoading}
                  />
                </Col>
              </Row>
            </Container>
          </Col>
          <span>
            Copyright &copy; {new Date().getFullYear()} World Gem Trade
          </span>

          <span>All Rights Reserved </span>
          <span>
            <a href="/terms-of-use" target="_blank">
              Terms of Use
            </a>{' '}
            /{' '}
            <a href="/privacy-policy" target="_blank">
              Privacy Policy
            </a>
          </span>
        </Row>
      </Form>
    </FormProvider>
  );
};

const Footer: React.FC = () => {
  return (
    <NewsletterProvider>
      <FooterComponent />
    </NewsletterProvider>
  );
};
export default Footer;
