import React, { useState } from 'react';
import classnames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { JOB_TITLES } from '../../utils/constants';
import { Row, Col } from 'reactstrap';

interface Props {
  setSection: (section: number) => void;
}

export default function PermissionForm({ setSection }: Props): JSX.Element {
  const [validation, setValidation] = useState<boolean>(false);

  const methods = useFormContext();
  const { register, getValues, errors } = methods;

  const nextStep = async () => {
    setValidation(false);
    const jobTitleIds = getValues('user.job_title_ids');
    if (jobTitleIds.length) {
      setSection(3);
    } else {
      setValidation(true);
    }
  };

  return (
    <section className="mt-5 noMobileMargins noMobilePaddings">
      <p className="large mb-2">Select your position.</p>
      {(validation || errors?.user?.job_title_ids) && (
        <p className="text-danger">
          Please select at least one permission to continue.
        </p>
      )}
      <div className="d-flex d-inline-flex">
        <div className="d-flex flex-column">
          <Row
            className={classnames({
              'border border-danger p-3':
                validation || errors?.user?.job_title_ids,
            })}
          >
            {JOB_TITLES.map((jobTitle, key) => (
              <Col key={key} className="text-nowrap px-3 mb-2" xs="auto">
                <label className="mb-0 mr-2" htmlFor={`id-${jobTitle.id}`}>
                  {jobTitle.name}
                </label>
                <input
                  type="checkbox"
                  name="user.job_title_ids"
                  ref={register}
                  value={jobTitle.id}
                  id={`id-${jobTitle.id}`}
                />
              </Col>
            ))}
          </Row>
          <div className="d-flex justify-content-end mt-1">
            <button
              className="btn-wgt dark-blue"
              type="button"
              onClick={nextStep}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
