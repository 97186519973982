import React from 'react';
import Footer from '../../components/Footer';
import Menu from '../../components/Menu';
import ManagementContent from './ManagementContent';

const Managements: React.FC = () => {
  return (
    <div className="management-page">
      <Menu />
      <ManagementContent />
      <Footer />
    </div>
  );
};

export default Managements;
