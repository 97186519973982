import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import RegisterFirmForm from '../../components/RegisterFirmForm';

export interface FirmTypes {
  type: string;
  title: string;
}

const firmTypes: FirmTypes[] = [
  { type: 'miner', title: 'Miner' },
  { type: 'retail', title: 'Retail' },
  { type: 'wholesale', title: 'Wholesale' },
  { type: 'manufacture', title: 'Manufacture' },
  { type: 'appraiser', title: 'Appraiser' },
  { type: 'laboratory', title: 'Laboratory' },
];

const RegisterContent: React.FC = () => {
  const params = useParams<{ type: string }>();
  const history = useHistory();

  const firmType: FirmTypes | undefined = useMemo(() => {
    const firmType2: FirmTypes | undefined = firmTypes.find(
      (fType) => fType.type === params.type,
    );

    if (!firmType2) {
      history.push('/');
    }

    return firmType2;
  }, [params.type, firmTypes]);
  return (
    <Container>
      <Row>
        <Col sm="7">
          <h2>{firmType?.title ?? ''}</h2>

          <p>
            We are going to create you a {firmType?.title?.toLowerCase() ?? ''}{' '}
            account.
          </p>
          <p>Do you have other office locations?</p>
        </Col>
        <Col>
          <p>
            We noticed that you do business in the{' '}
            {firmType?.title?.toLowerCase() ?? ''} sector with offices in
            Antwerp, etc.
            <br />
            <br />
            We are here to help.
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <h4>Your Office Location</h4>
        </Col>
        <Col sm="12">
          <RegisterFirmForm />
        </Col>
        <Col sm="12">
          <p className="text-center mt-5">
            World Gem Trade is connected with your company. I just sent you a
            regiseration email to (email@email.com).
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default RegisterContent;
